import React from "react";
import CreateInteractiveMap from "./CreateInteractiveMap";
function FormPage3(props) {
  const {
    streetLat,
    streetLng,
    foundStatus,
    pg2StreetAdress,
    setpg2StreetAdress,
    loadingInfoForm,
    step,
    setStep,
    setPageNumber,
  } = props;
  const handleContinue_pg3 = (e) => {
    moveProgressBarPageAhead();
    goToPage(4);
  };
  const handleGoBackPage2 = (e) => {
    moveProgressBarPageBack();
    goToPage(2);
  };
  function goToPage(newPageNumber) {
    setPageNumber(newPageNumber);
  }
  const TOTAL_PAGES = 7;
  function moveProgressBarPageAhead() {
    if (step < TOTAL_PAGES) {
      setStep(step + 1);
    }
  }
  function moveProgressBarPageBack() {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  return (
    <>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer p-0 "
        id="boxPage-3"
      >
        <CreateInteractiveMap
          lat={streetLat}
          lng={streetLng}
          found={foundStatus}
          handleGoBackPage2={handleGoBackPage2}
          handleContinue_pg3={handleContinue_pg3}
          pg2StreetAdress={pg2StreetAdress}
          setpg2StreetAdress={setpg2StreetAdress}
          loadingInfoForm={loadingInfoForm}
        />
      </div>
    </>
  );
}

export default FormPage3;
