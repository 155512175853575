import React, { useState,useEffect } from "react";
import "../../assets/css/style.css";
import "../../assets/css/style-react.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import FormPage1 from "./Components/FormPage1";
import FormPage2 from "./Components/FormPage2/FormPage2";
import FormPage3 from "./Components/FormPage3/FormPage3";
import FormPage4 from "./Components/FormPage4";
import FormPage5 from "./Components/FormPage5";
import FormPage6 from "./Components/FormPage6";
import FormPage7 from "./Components/FormPage7";
import { useLocation } from "react-router-dom";
function Form(props) {
  const {navOpacity,setNavOpacity}=props;
  useEffect(() => {
  setNavOpacity(1)
  }, []);
  const location = useLocation();

  const data = location.state;

  //  page-1
  const [billPrice, setBillPrice] = useState(100000);
  const [pageNumber, setPageNumber] = useState(1);
  console.log("page= ", pageNumber);
  const [cityOrZip, setCityOrZip] = useState("");
  const [cityAndZip, setCityAndZip] = useState(`${cityOrZip}`);
  const [latitude, setlatitude] = useState(40.0);
  const [longitude, setlongitude] = useState(49.06);
  const [step, setStep] = useState(1);
  const [homes, sethomes] = useState(1);
  if (data && homes === 1) {
    console.log("x");
    setPageNumber(2);
    setStep(2);
    sethomes(2);
    setBillPrice(data.billp);
    setCityAndZip(data.city);
    setlatitude(data.hlat);
    setlongitude(data.hlng);
  }
  const stateProps1 = {
    setPageNumber,
    setCityAndZip,
    setlatitude,
    setlongitude,
    step,
    setStep,
    cityOrZip,
    setCityOrZip,
    billPrice,
    setBillPrice,
  };
  //  page-2
  const [pg2StreetAdress, setpg2StreetAdress] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetLat, setstreetLat] = useState(45);
  const [streetLng, setstreetLng] = useState(78);
  const [foundStatus, setfoundStatus] = useState(true);
  const [loadingInfoForm, setloadingInfoForm] = useState(
    "Po verifikojmë pozicionin"
  );
  const [finalLatitude, setfinalLatitude] = useState(54);
  const [finalLongitude, setfinalLongitude] = useState(43);
  const stateProps2 = {
    setPageNumber,
    cityAndZip,
    latitude,
    longitude,
    step,
    setStep,
    streetAddress,
    setStreetAddress,
    setpg2StreetAdress,
    setstreetLat,
    setstreetLng,
    setfoundStatus,
    setloadingInfoForm,
    setfinalLatitude,
    setfinalLongitude,
  };

  // page=3
  const stateProps3 = {
    streetLat,
    streetLng,
    foundStatus,
    pg2StreetAdress,
    setpg2StreetAdress,
    loadingInfoForm,
    step,
    setStep,
    setPageNumber,
  };

  //  page-4
  const [sunCoverage, setSunCoverage] = useState("");
  const stateProps4 = { setSunCoverage, setPageNumber, step, setStep };

  // page=5
  const [customerType, setCustomerType] = useState("");
  const stateProps5 = {
    setPageNumber,
    step,
    setStep,
    customerType,
    setCustomerType,
  };

  // page-6
  const stateProps6 = {
    setPageNumber,
    step,
    setStep,
    billPrice,
    cityAndZip,
    customerType,
    finalLatitude,
    finalLongitude,
    pg2StreetAdress,
    sunCoverage,
  };
  // progress-bar
  const TOTAL_PAGES = 7;
  const width = (100 * step) / TOTAL_PAGES;
  return (
    <>
      <div className="msgPopup">
        <div className="msgPopupWrapper">
          <div className="msgPopup-content">
            <h2>Best Solar</h2>
            <p>Plotesoni formularin perpara se te largoheni</p>
            <button
              className="btn btn-primary text-light fw-500"
              id="closePopup"
            >
              OK
            </button>
          </div>
        </div>
      </div>

      <section className="bg-light">
        <div className="container py-5 text-center about-us-container">
          <div className="row">
            <div className="col col-xl-8 mx-auto">
              <h1 className="display-3 mb-3 fw-normal">Llogarit kursimin</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container-xl px-3 px-xl-0 pt-0">
          <div className="row mx-auto gy-5 pb-5">
            <div
              className="col-sm-10 col-md-8 col-lg-7 col-xl-6 mx-auto"
              id="formBox"
            >
              <div id="pageProgress">
                <div id="progressBar" style={{ width: `${width}%` }}></div>
              </div>

              {/* Page 1 */}
              {pageNumber === 1 && <FormPage1 {...stateProps1} />}

              {/* Page 2  */}
              {pageNumber === 2 && <FormPage2 {...stateProps2} />}
              {/* Page 3  */}
              {pageNumber === 3 && <FormPage3 {...stateProps3} />}
              {/* Page 4 */}
              {pageNumber === 4 && <FormPage4 {...stateProps4} />}
              {/* page 5  */}

              {pageNumber === 5 && <FormPage5 {...stateProps5} />}

              {/* page 6  */}
              {pageNumber === 6 && <FormPage6 {...stateProps6} />}

              {/* page 7 */}
              {pageNumber === 7 && <FormPage7 />}
              <div className="alignRight">
                <div className="ms-md-auto pb-3 pb-md-0 btn-share-container">
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=https://dev.ermalaliraj.com/bestsolar/pages/blog/blogMain.html"
                    target="_blank"
                    className="btn-share btn-facebook formfbButton "
                    style={{marginRight:"10px"}}
                  >
                    <FontAwesomeIcon icon={faFacebook} style={{fontSize:'24px' ,marginRight:"10px",marginBottom:"-2px"}} />
                    <span>Share</span>
                  </a>
                  <a
                    href="/"
                    target="_blank"
                    className="btn-share btn-whatsapp"
                  >
                   <FontAwesomeIcon icon={faWhatsapp} style={{fontSize:'24px' ,marginRight:"10px",marginBottom:"-2px"}}/>
                    <span>Share</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Form;
