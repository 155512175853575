import React from "react";
import { BsArrowLeft } from "react-icons/bs";

function FormPage5(props) {
  const { setPageNumber, step, setStep, customerType, setCustomerType } = props;
  const handlegoBackPage4 = () => {
    moveProgressBarPageBack();
    goToPage(4);
  };
  function goToPage(newPageNumber) {
    setPageNumber(newPageNumber);
  }
  const TOTAL_PAGES = 7;
  function moveProgressBarPageAhead() {
    if (step < TOTAL_PAGES) {
      setStep(step + 1);
    }
  }
  function moveProgressBarPageBack() {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  const handleIndividualClick = () => {
    setCustomerType("INDIVID");
    moveProgressBarPageAhead();
    goToPage(6);
  };
  const handleBusinessClick = () => {
    setCustomerType("BIZNES");
    moveProgressBarPageAhead();
    goToPage(6);
  };
  return (
    <>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer p-0 "
        id="boxPage-5"
      >
        <div className="d-flex flex-column infoBox2">
          <span
            className="go-back align-self-start text-white fs-0 mb-2 fw-500"
            id="goBackLink_pg5"
            onClick={handlegoBackPage4}
          >
            <BsArrowLeft />
            Kthehu mbrapa
          </span>
          <h3 className="text-light text-center mb-4" id="customerType-text">
            Zgjidhni llojin e përdoruesit të energjisë
          </h3>
          <button
            className={`btn btn-light text-primary-col mb-3 w-60 mx-auto companyBtn fw-500 ${
              customerType === "INDIVID"
                ? "companyBtnSelected"
                : "companyBtnNotSelected"
            }`}
            id="individual-utility-btn"
            onClick={handleIndividualClick}
          >
            INDIVID
          </button>
          <button
            className={`btn btn-light text-primary-col mb-3 w-60 mx-auto companyBtn fw-500 ${
              customerType === "BIZNES"
                ? "companyBtnSelected"
                : "companyBtnNotSelected"
            }`}
            id="business-utility-btn"
            onClick={handleBusinessClick}
          >
            BIZNES
          </button>
        </div>
      </div>
    </>
  );
}

export default FormPage5;
