import React ,{useEffect}from "react";
import "../assets/css/style.css";
import "../assets/css/style-react.css";
import paneletfotovoltaike from "../assets/img/panelet-fotovoltaike/panelet-fotovoltaike_main.png";
import monocrystaline1 from "../assets/img/panelet-fotovoltaike/monocrystaline1.png";
import polycrystaline1 from "../assets/img/panelet-fotovoltaike/polycrystaline1.png";
import solarenergykur from "../assets/img/panelet-fotovoltaike/solar-energy-kursimet.jpg";
function Solarpanels(props) {
  const {navOpacity,setNavOpacity}=props;
  useEffect(() => {
  setNavOpacity(1)
  }, []);
  return (
    <>
      <section className="solar-panels-img-container">
        <div className="container text-center solar-panels-container">
          <div className="row">
            <div className="col col-xl-8 mx-auto">
              <h1 className="display-4 mb-3 fw-500 text-white">
                Çfarë janë panelet fotovoltaike?
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container-xl px-3 px-xl-0 pt-5">
          <div className="row mx-auto gy-5 pb-5">
            <div className="col-lg-10 mx-auto solar-page-info border-right-0">
              <div className="solar-panels-info mb-4">
                <h2 className="mb-3 text-primary-col">
                  Panelet fotovoltaike dhe llojet e tyre
                </h2>
                <img src={paneletfotovoltaike} alt="" className="img-fluid" />
                <div>
                  <p className="lead pt-3">
                    Panelet fotovoltaike janë një mënyrë efiçente për të
                    shfrytëzuar energjinë e diellit për të prodhuar
                    elektricitet. E vetmja gjë që ju nevojitet është drita e
                    diellit dhe një vend ku mund t’i instaloni, dhe mund të
                    filloni menjëherë ta prodhoni vetë energjinë.
                  </p>
                  <p className="lead">
                    Panelet diellore punojnë duke thithur dritën e diellit, duke
                    e kthyer në korent elektrik. Elektriciteti rrjedh përmes
                    kabllove që lidhen me inverterin duke e kthyer në korentin e
                    alternuar (AC), si ai që vjen në prizat e shpëpisë apo
                    biznesit tuaj.{" "}
                  </p>
                  <p className="lead">
                    Panelet diellore apo fotovoltaike janë ndërtuar nga
                    materiale gjysëmpërçues si silikoni ose teluridi i
                    kadmiumit. Panelet fotovoltaike më të përhapura përbëhen nga
                    silikoni kristalin.
                  </p>
                  <p className="lead">
                    Zakonisht panelet fotovoltaike vishen me një shtresë të
                    hollë prej materiali plastik të quajtuar PV (fluorid
                    polyvinylidene), më qëllim që të mbrohen qelizat nga
                    faktorët atmosferikë, por edhe për të rritur eficencen e
                    procesit të prodhimit. PVF, ndryshe e njohur si fluorid
                    polivinili, është një elastomer termoplastik (TPE).
                  </p>
                </div>
              </div>
              <div className="py-2 mb-4">
                <h2 className="mb-3 text-primary-col">
                  Llojet e paneleve fotovoltaike
                </h2>
                <p className="lead">
                  Panelet diellore ndahen në dy lloje kryesore: monokristaline
                  dhe polikristaline.{" "}
                </p>
              </div>
              <div className="d-flex flex-column flex-md-row mb-4">
                <div className="panel-type-info w-60">
                  <h3 className="mb-3">Monokristaline</h3>
                  <p className="lead">
                    Panelet monokristaline përbëhen nga një kristal i vetëm,
                    ndryshe nga polikristalinet, që përbëhen nga shumë kristale.
                    Panelet monokristaline janë më të përhaurat, pasi përmbajnë
                    vetëm një shtresë të madhe silikoni. Po ashtu ato e kanë
                    rendimentin më të lartë, që nënkupton se konvertojnë më
                    shumë energji diellore në elektricitet. Panelet
                    poliktristaline përbëhen nga shumë kristale. Sipërfaqja e
                    tyre ngjan si një mozaik kristalesh. Rendimenti është pak më
                    i ulët se ai i paneleve monokristaline, por kushtojnë më
                    lirë se ato.
                  </p>
                </div>
                <div className="panel-type-img w-40 text-center pt-4">
                  <img src={monocrystaline1} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row mb-5">
                <div className="panel-type-info w-60">
                  <h3 className="mb-3">Polikristaline</h3>
                  <p className="lead">
                    Qelizat poliktristaline përmbajnë një sërë materialesh
                    kristaline dhe funksionojnë si një bateri makinash hibride.
                    Ato përbëhen nga disa shtresa qelizash kristali silikoni të
                    vendosura mbi njëra tjetrën. Çdo qelizë përbëhet nga
                    silikoni i vendosur si sanduiç midis dy gjysëmpërçuesve të
                    ndryshëm. Njëri përmban metale si bakri ose alumini që
                    përdoren për elektrodat, ndërsa tjetri përmban tellurid
                    cadmiumi që përdoret për fotoelektrodat. Elektrodat e fundit
                    mbulohen me një shtresë të hollë plastike për t’i mbrojtur
                    nga agjentët atmosferikë.
                  </p>
                </div>
                <div className="panel-type-img w-40 text-center pt-4">
                  <img src={polycrystaline1} alt="" className="img-fluid" />
                </div>
              </div>
              <div
                className="affordable-panels-info pt-3"
                id="affordable-panels-info"
              >
                <h2 className="text-primary-col mb-3">
                  A janë të përballueshme panelet fotovoltaike?
                </h2>
                <img src={solarenergykur} className="img-fluid mb-3" alt="" />
                <div className="affordable-content">
                  <p className="lead">
                    Përgjigja është po! Janë të përballueshme dhe me kosto
                    efektive. Përvecse ulin faturën tuaj mujore të energjisë,
                    panelet fotovoltaike rrisin vlerën e pronës tuaj, qoftë
                    shtëpi apo biznes. Kostoja e panelit llogaritet mesatarisht
                    1euro – 2 euro për ëat, por mund të ndryshojë në varësi të
                    madhësisë së impiantit, vecanërisht për bizneset. Nëse
                    instaloni një panel më të lirë, do të keni rendiment më të
                    ulët, që do të thotë se do t’ju duhet të instaloni më shumë
                    panele, për të arritur sasinë e elektricitetit që do të
                    prodhonte një panel më i shtrenjtë.
                  </p>
                  <p className="lead">
                    Në Shqipëri ekzistojnë forma të ndryshme financimi dhe
                    grante për instalimin e paneleve fotovoltaike, si për
                    familje edhe për biznese. Për t’u informuar më shumë rreth
                    mundësive dhe përfitimit të granteve, na kontaktoni (shtypet
                    dhe hapet seksioni na kontaktoni).
                  </p>
                  <p className="lead">
                    Nëse doni të instaloni panele fotovoltaike, mund të merrni
                    një konsultim falas për të kuptuar si do të jetë procesi.
                    Duke qenë se do të hyni në një kontratë afatgjatë, është e
                    rëndësishme të merrni kohën e duhur për të peshuar
                    përfitimet dhe anët jo të mira, para se të merrni një vendim
                    përfundimtar.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Solarpanels;
