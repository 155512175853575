import React,{useEffect} from "react";
import aboutUsImg from "../assets/img/aboutUs.jpeg";
import targetIcon from "../assets/img/icons/lineal/target.svg";
import awardIcon from "../assets/img/icons/lineal/award-2.svg";
import "../assets/css/style-react.css";

function About(props) {
  const {navOpacity,setNavOpacity}=props;
  useEffect(() => {
  setNavOpacity(1)
  }, []);
  return (
    <>
      <section className="bg-light aboutusfirstsection">
        <div className="container py-5 text-center about-us-container">
          <div className="row">
            <div className="col col-xl-8 mx-auto">
              <h1 className="display-3 mb-3 fw-normal">
                Rreth Best Solar Albania
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light aboutusSecondSection">
        <div className="container-xl px-3 px-xl-0 pt-5">
          {/* Who Are We? */}
          <div className="row mx-auto gy-5 pb-5">
            <div className="col-md-9 col-lg-7 col-xl-7">
              <div className="p-3 pt-2 pe-3">
                <p className="pb-2">
                  Best Solar Albania është një burim online për të mësuar dhe
                  për t’u informuar mbi energjinë diellore. Këtu do të gjeni
                  informacion lidhur me arsyet për të instaluar panele
                  fotovoltaike që sot, kostot e tyre dhe llogaritjen e kursimit.
                  Përmes një ekipi të kualifikuar e me përvojë, ju ofrojmë
                  konsulencë falas për të përcaktuar nëse instalimi i paneleve
                  fotovoltaike vlen për biznesin apo shtëpinë tuaj.
                </p>
                <p className="pb-2">
                  Shfrytëzimi i energjisë diellore ka marrë hov në Shqipëri pasi
                  njerëzit po ndërgjegjësohen përditë e më shumë mbi përfitimet
                  që ka. Për më tepër, Best Solar Albania ua lehtëson pronarëve
                  të shtëpive apo bizneseve analizën e kostos për panelet
                  fotovoltaike, e cila i ndihmon për të përcaktuar sa para
                  kursejnë duke kaluar në energji diellore.
                </p>
                <p className="pb-2">
                  Në Best Solar Albania, ne i trajtojmë klientët tanë me respekt
                  dhe dinjitet. Ekspertët tanë janë në dispozicionin tuaj në cdo
                  ditë të javës, si dhe konsulenca në faqen tonë online.
                </p>
                <p className="pb-2">
                  Duke investuar në panele fotovoltaike, ju mund të filloni të
                  kurseni menjëherë.
                </p>
                <p className="">
                  Presim të punojmë me ju për ta bërë shtëpinë apo biznesin tuaj
                  më efiçent dhe më të qëndrueshëm.
                </p>
                <p className="">
                  Qëllimi ynë është t’ju ofrojmë të gjithë informacionin e
                  nevojshëm në një vend, dhe pa kosto.
                </p>
              </div>
            </div>

            {/* djdjjdjdj */}

            <div className="who-we-are col-sm-10 col-lg-5 ms-lg-auto mx-auto ps-md-4 ps-xl-0 pt-3">
              <div className="col mx-lg-auto position-relative">
                <div className="img-container">
                  <img src={aboutUsImg} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col">
                <div className="row gx-xl-10 gy-6 pt-5 pb-4">
                  <div className="col-sm-6 pb-4 pb-md-0">
                    <div className="d-flex flex-xl-row flex-lg-column text-lg-center text-xl-start">
                      <div>
                        <img
                          src={targetIcon}
                          className="me-3"
                          style={{ width: "60px" }}
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="mb-1">Misioni Ynë</h4>
                        <p className="mb-0">
                          Misioni ynë është të ndihmojmë bizneset dhe familjet
                          të kuptojnë dhe të informohen mbi sistemet e energjisë
                          diellore, dhe të bëjnë zgjedhjen më të mirë për të
                          prodhuar energji në mënyrë të pavarur.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex flex-xl-row flex-lg-column text-lg-center text-xl-start">
                      <div>
                        <img
                          src={awardIcon}
                          className="me-3"
                          style={{ width: "60px" }}
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="mb-1">Vlerat Tona</h4>
                        <div className="mb-0">
                          <ul>
                            <li>Integriteti</li>
                            <li>Besueshmëria</li>
                            <li>Dedikimi</li>
                            <li>Inovacioni</li>
                            <li>Partneriteti</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
