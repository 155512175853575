import React from "react";
import { BsArrowLeft } from "react-icons/bs";
function FormPage4(props) {
  const { setSunCoverage, setPageNumber, step, setStep } = props;
  const handlegoBackPage3 = () => {
    moveProgressBarPageBack();
    goToPage(3);
  };
  const handleSunCoverageClick = (value) => {
    setSunCoverage(value);
    setTimeout(() => {
      moveProgressBarPageAhead();
      goToPage(5);
    }, 500);
  };
  function goToPage(newPageNumber) {
    setPageNumber(newPageNumber);
  }
  const TOTAL_PAGES = 7;
  function moveProgressBarPageAhead() {
    if (step < TOTAL_PAGES) {
      setStep(step + 1);
    }
  }
  function moveProgressBarPageBack() {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  return (
    <div>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer p-0"
        id="boxPage-4"
      >
        <div className="d-flex flex-column infoBox2">
          <span
            className="go-back align-self-start text-white fs-0 mb-2 fw-500"
            id="goBackLink_pg4"
            onClick={handlegoBackPage3}
          >
            <BsArrowLeft />
            Kthehu mbrapa
          </span>
          <h3 className="text-light text-center mb-4" id="sun-coverage-text">
            Sa diell sheh çatia juaj?
          </h3>
          <button
            className="btn btn-light text-primary-col mb-3 w-60 mx-auto companyBtn fw-500"
            id="sun-coverage-fullSun-btn"
            onClick={() =>
              handleSunCoverageClick("Diell të plotë pjesën më të madhe")
            }
          >
            Diell të plotë pjesën më të madhe
          </button>
          <button
            className="btn btn-light text-primary-col mb-3 w-60 mx-auto companyBtn fw-500"
            id="sun-coverage-partialSun-btn"
            onClick={() => handleSunCoverageClick("Pjesërisht")}
          >
            Pjesërisht
          </button>
          <button
            className="btn btn-light text-primary-col mb-3 w-60 mx-auto companyBtn fw-500"
            id="sun-coverage-noSun-btn"
            onClick={() => handleSunCoverageClick("Hije pjesën më të madhe")}
          >
            Hije pjesën më të madhe
          </button>
          <button
            className="btn btn-light text-primary-col mb-3 w-60 mx-auto companyBtn fw-500"
            id="sun-coverage-notSure-btn"
            onClick={() => handleSunCoverageClick("Nuk jam i/e sigurtë")}
          >
            Nuk jam i/e sigurtë
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormPage4;
