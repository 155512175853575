import React, { useState, useEffect } from "react";
import blog1_main from "../../assets/img/blog-1/blog1_main.png";
import goodEnergyCompany from "../../assets/img/old/good-energy-company.png";
import solarSystem from "../../assets/img/old/solar-system.png";
import "../../assets/css/style.css";
import "../../assets/css/style-react.css";

function Blog1HowToChooseTheRightCompany(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {navOpacity,setNavOpacity}=props;
  useEffect(() => {
  setNavOpacity(1)
  }, []);
  // Preload images
  useEffect(() => {
    new Image().src = blog1_main;
    new Image().src = goodEnergyCompany;
    new Image().src = solarSystem;
  }, []);

  function handlePrevClick() {
    setCurrentSlide(currentSlide === 0 ? 2 : currentSlide - 1);
  }

  function handleNextClick() {
    setCurrentSlide(currentSlide === 2 ? 0 : currentSlide + 1);
  }
  console.log(`currentSlide: ${currentSlide}`);
  return (
    <>
      <section className="bg-light blog1Section-1">
        <div className="container py-5 blog-page-content blog-title-container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h1 className="display-4 mb-5 fw-normal">
                Si të përzgjidhni kompaninë e duhur për investimin në panele
                fotovoltaike
              </h1>
              <h2 className="post-title mb-3 display-6 fw-normal  headingblog1colorchanging">
                7 Këshilla dhe disa pyetje
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 pb-5 mx-auto">
              <div className="blog-card-wrapper2">
                <div className="card">
                  <div className="carousel">
                    <div className="carousel-images">
                      <img
                        src={blog1_main}
                        alt="Slide 1"
                        className={`carousel-image ${
                          currentSlide === 0 ? "active" : ""
                        }`}
                      />
                      <img
                        src={goodEnergyCompany}
                        alt="Slide 2"
                        className={`carousel-image ${
                          currentSlide === 1 ? "active" : ""
                        }`}
                      />
                      <img
                        src={solarSystem}
                        alt="Slide 3"
                        className={`carousel-image ${
                          currentSlide === 2 ? "active" : ""
                        }`}
                      />
                    </div>
                    <div className="carousel-buttons">
                      <button
                        onClick={handlePrevClick}
                        className="prev"
                      ></button>
                      <button
                        onClick={handleNextClick}
                        className="next"
                      ></button>
                    </div>
                  </div>

                  <div className="card-body cardborderblog1">
                    <div className="post-content position-relative">
                      <p>
                        Para se të investoni në panele fotovoltaike, është e
                        rëndësishme të gjeni kompaninë e duhur që ofron
                        shërbimet që ju duhen.
                      </p>
                      <div>
                        <h5 className="mb-3">
                          1. Së pari mësoni madhësinë e sistemit që ju
                          nevojitet.
                        </h5>
                        <p>
                          Sa më i madh të jetë sistemi juaj, aq më shumë do të
                          kushtojë dhe aq më e gjatë koha për ta vënë në punë.
                          Ju mund të na kontaktoni dhe të merrni një vlerësim
                          për të parë se sa do t'ju kushtonte për sisteme
                          fotovoltaike me madhësi të ndryshme. Gjithçka varet
                          nga çatia dhe vendndodhja juaj. Nëse keni një çati të
                          sheshtë, do të jetë më e vështirë për t'u instaluar.
                          Nëse keni një çati me kënd, do të jetë më e lehtë.
                          Kompania e duhur duhet të shikojë edhe buxhetin tuaj.
                          Do të doni të gjeni një kompani që mund t'ju ndihmojë
                          ta shlyeni sistemin tuaj në kohë, që të mund të arrini
                          mkimumin e kursimit.
                        </p>
                      </div>
                      <div>
                        <h5 className="mb-3">
                          2. Sigurohuni që kompania të ofrojë shërbim shumë të
                          mirë ndaj klientit
                        </h5>
                        <img
                          src="../../assets/img/blog-1/sherbim_klienti_rs.jpg"
                          alt=""
                          className="img-fluid mb-3"
                        />
                        <p>
                          Si për cdo produkt tjetër me vlerë, mundohuni të
                          zbuloni përvojat e klientëve të mëparshëm apo të
                          lexoni referencat e tyre. Nëse kompania ka reputacion
                          të mirë, mund të ndiheni më të sigurtë për blerjen
                          tuaj.
                        </p>
                        <p>
                          Po ashtu informohuni mbi periudhën e garancisë që
                          ofron kompania, apo ndonjë rimbursim, në rast se
                          sistemi fotovoltaik nuk po prodhon energji aq sa
                          pritej.
                        </p>
                      </div>
                      <div>
                        <h5 className="mb-3">
                          3. Pyesni për mundësitë e financimit
                        </h5>
                        <p>
                          Kompanitë shpesh mund t’ju prezantojnë me mundësi të
                          ndryshme financimi, si nga kompania, nga bankat apo
                          grante për eficencen e energjisë.
                        </p>
                      </div>

                      <div>
                        <h5 className="mb-3">
                          4. Shikoni jetëgjatsinë e sistemit fotovoltaik
                        </h5>
                        <p>
                          Panelet fotovoltaike kanë goxha jetëgjatësi, rreth 25
                          vjet, por do ju duhet të dini a do të jetë po aq
                          jetëgjatë kompania që po jua ofron. A do të jetë
                          kompania edhe pas 15 vjetësh, që t’ju sigurojë
                          mirëmbajtjen e paneleve tuaja? Është e rëndësishme që
                          kompania jo vetëm të ketë mundësi t’ju ofrojë
                          mirëmbajtjen, por edhe shërbime riparimi në rast
                          defektesh.{" "}
                        </p>
                      </div>

                      <div>
                        <h5 className="mb-3">5. Kërkoni një kopje kontrate</h5>
                        <p>
                          Kontrata duhet të tregojë të gjitha kushtet e
                          marrëveshjes. Para se të angazhoheni, kontrolloni me
                          kujdes kontratën dhe sigurohuni që të mos ketë kosto
                          apo tarifa të fshehura në të.
                        </p>
                      </div>
                      <div>
                        <h5 className="mb-3">6. Kërkoni referenca</h5>
                        <p>
                          These are people who have already purchased solar
                          panels through this company and reviewed their
                          experience so you can see what it’s like to work with
                          them. You know that the company has good customer
                          service because other people also enjoyed working with
                          them.{" "}
                        </p>
                        <p>
                          You can ask questions such as , is the company
                          available to help you install your system? Is it easy
                          for them to get back to you with answers to your
                          questions? How are they at getting you what you need?
                        </p>
                      </div>
                      <div>
                        <h5 className="mb-3">7. Merrni disa oferta</h5>
                        <p>
                          Sigurohuni që të konsultoheni me disa kompani derisa
                          të gjeni atë që i përshtatet më mirë nevojave tuaja.
                          Në treg ka kompani të mira, thjesht sigurohuni duke
                          bërë pyetjet e mësipërme para se të firmosni
                          kontratat.{" "}
                        </p>
                      </div>
                      <div className="mb-5 mt-5">
                        <h4 className="mb-3 headingblog1colorchanging">
                          Disa pyetje që duhet t’i bëni kompanive para
                          përgjedhjes
                        </h4>
                      </div>
                      <div>
                        <p>
                          <strong>Sa do të kursej? </strong> Para se të zgjidhni
                          kompaninë e paneleve fotovoltaike, pyetni kompaninë sa
                          do kurseni në vitet e ardhshme me systemin e
                          propozuar.
                        </p>
                        <p>
                          <strong>Sa do të kushtojë? </strong>  Sigurohuni para
                          se të firmosni që të mos ketë tarifa të fshehura gjatë
                          procesit, përvec atyre të përmendura në kontratë. Për
                          cdo rast, kërkoni paraprakisht një kopje kontrate.
                        </p>
                        <p>
                          <strong>Për sa kohë shlyhet investimi? </strong>  Kjo
                          do t’ju japë një ide sa kohë do ju duhet që t’ju
                          kthehet mbrapsht investimi në systemin fotovoltaik.
                        </p>
                        <p>
                          <strong>
                            A ka mundësi financimi/pagesa me këste/incentiva? 
                          </strong>{" "}
                            Incentivat mund t’ju ndihmojnë ta blini sistemin më
                          shpejt. Mund të gjeni kompani që ju ndihmojnë të
                          përfitoni nga mundësitë e financimit apo incentivat.
                        </p>
                        <p>
                          <strong>Sa garanci ofroni? </strong>  Kompanitë me
                          reputacion të mirë ofrojnë 15 vite garanci për
                          produktet e tyre. Këtë informacion normalisht mund ta
                          gjeni edhe në faqet e tyre online.
                        </p>
                        <p>
                          <strong>Sa është jetëgjatësia e paneleve? </strong>{" "}
                          Panelet fotovoltaike funksionojnë pa probleme për
                          rreth 25 vjet, por duhet të siguroheni që edhe nëse
                          biznesi nuk operon gjatë kësaj periudhe, të keni
                          opsione për të siguruar mirëmbajten e tyre.
                        </p>
                        <br />
                        <p>
                          Si përfundim, që të zgjidhni kompaninë e duhur, që
                          ofron impiantet e duhura për ju, duhet të gjeni
                          kompaninë që është e gatshme t’ju ndihmojë me të
                          gjitha opsionet e mundshme. Pra zgjidhni një kompani
                          që ofron shërbim shumë të mirë ndaj klientit, opsione
                          financimi dhe panele cilësore. Vetëm kështu mund të
                          jeni të sigurt ër të marrë vendimin tuaj.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog1HowToChooseTheRightCompany;
