import { useEffect, useState } from "react";

import CircleProgressStatus from "./CircleProgressStatus";

const CreateInteractiveMap = ({
  lat,
  lng,
  found,
  handleGoBackPage2,
  handleContinue_pg3,
  pg2StreetAdress,
  setpg2StreetAdress,
  loadingInfoForm,
}) => {
  const GKEY = "AIzaSyClhEC4DhecYRAyD3qKg7YXTmikgBI5Wx4";

  const [streetAddressInfo, setStreetAddressInfo] = useState(
    `${pg2StreetAdress}`
  );
  const [loadingInfo, setLoadingInfo] = useState(`${loadingInfoForm}`);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  useEffect(() => {
    const loadMap = async () => {
      const mapOptions = {
        center: { lat, lng },
        zoom: 13,
        mapTypeId: "satellite",
        maxZoom: 19,
      };
      const mapInstance = new window.google.maps.Map(
        document.getElementById("map-2"),
        mapOptions
      );
      setMap(mapInstance);

      if (found) {
        let seconds = 0;
        let zoomInterval = setInterval(() => {
          const currentZoom = mapInstance.getZoom();
          const maxZoom = mapInstance.maxZoom;
          if (currentZoom < maxZoom) {
            mapInstance.setZoom(currentZoom + 1);
          } else {
            clearInterval(zoomInterval);
          }

          if (seconds === 1) {
            setLoadingInfo("Duke kërkuar çatinë tuaj");
          }
          if (seconds === 4) {
            setLoadingInfo("Ne e gjetëm çatinë tuaj");
          }
          seconds++;
        }, 800);
      }

      let marker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapInstance,
      });

      // Add new location on click
      window.google.maps.event.addListener(
        mapInstance,
        "click",
        async function (event) {
          if (marker) {
            marker.setMap(null);
          }
          marker = new window.google.maps.Marker({
            position: event.latLng,
            map: mapInstance,
          });
          mapInstance.panTo(marker.getPosition());

          let newMarkerLat = marker.getPosition().lat();
          let newMarkerLng = marker.getPosition().lng();
          let address = await getAddressFromLocation([
            newMarkerLat,
            newMarkerLng,
          ]);

          setStreetAddressInfo(address);
          setpg2StreetAdress(address);
          setLoadingInfo("Lokalizoni çatinë manualisht!");
        }
      );
    };
    loadMap();
  }, [lat, lng, found]);

  const getAddressHumanName = (results) => {
    for (let i = 0; i < results.length; i++) {
      if (!results[i].formatted_address.includes("+")) {
        return results[i].formatted_address;
      }
    }
    return "";
  };

  const getAddressFromLocation = async (latlng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${GKEY}`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const humanAddress = getAddressHumanName(data.results);
        return humanAddress;
      } else {
        throw new Error(
          "Geocode was not successful for the following reason: " + data.status
        );
      }
    } catch (error) {
      throw new Error(
        "Error in getAddressFromLocation calling: https://maps.googleapis.com/maps/api/geocode/json" +
          error.message
      );
    }
  };

  return (
    <>
      <div>
        <div id="map-2"></div>
      </div>
      <CircleProgressStatus
        streetAddressInfo={streetAddressInfo}
        loadingInfo={loadingInfo}
        handleGoBackPage2={handleGoBackPage2}
        handleContinue_pg3={handleContinue_pg3}
        found={found}
      />
    </>
  );
};

export default CreateInteractiveMap;
