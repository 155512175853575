import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { BiPhoneCall } from "react-icons/bi";
import { BiEnvelope } from "react-icons/bi";
import { Link } from "react-router-dom";
import "../assets/css/style-react.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

// add the required icons to the library
library.add(fab);

function Footer() {
  return (
    <>
      <footer className="bg-footer text-light pt-5 pb-4 px-3">
        <div className="container-xxl">
          <div className="row footer-info pb-4">
            <div className="col-md-3 footer-col-sm-width">
              <div className="mb-4 p-3">
                <div>
                  <h3>Best Solar Albania</h3>
                </div>
                <div className="mb-5">
                  <p>
                    <Link to="/about" className="text-light">
                      Rreth Nesh
                    </Link>
                  </p>
                  <p>
                    <Link to="/blog" className="text-light">
                      Blog
                    </Link>
                  </p>
                  <p>
                    <Link to="/contact" className="text-light">
                      Na kontaktoni
                    </Link>
                  </p>
                </div>
                <div>
                  <p className="lead-footer">
                    Ne jemi <strong>Best Solar Albania </strong>Misioni ynë
                    është të ndihmojmë bizneset dhe familjet të kuptojnë dhe të
                    informohen mbi sistemet e energjisë diellore, dhe të bëjnë
                    zgjedhjen më të mirë për të prodhuar energji në mënyrë të
                    pavarur.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 footer-col-sm-width">
              <div className="mb-4 p-3">
                <div className="get-in-touch mb-4">
                  <h3 className="mb-2">Na kontaktoni!</h3>
                  <div>
                    <div className="d-flex align-items-center mb-2">
                      <BiPhoneCall className="me-3" />
                      <p>+355 69 666 6156</p>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <BiEnvelope className="me-3" />
                      <p>info@bestsolar.al</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 footer-col-sm-width">
              <div className="mb-4 p-3">
                <div>
                  <h3>Panelet fotovoltaike</h3>
                  <br />
                </div>
                <div>
                  <p>
                    <Link to="/solarpanels" className="text-light">
                      Çfarë janë?
                    </Link>
                  </p>
                  <p>
                    <a
                      href="@@webRoot/pages/solarPanels.html#affordable-panels-info"
                      className="text-light"
                    >
                      A janë të përballueshme?
                    </a>
                  </p>
                  <p>
                    <Link to="/form" className="text-light">
                      Sa kurseni?
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3 footer-col-sm-width">
              <div className="mb-4 p-3">
                <div className="get-in-touch mb-3">
                  <h3 className="mb-2">Follow Us</h3>
                  <div className="d-flex align-items-center">
                    <a
                      href="https://www.facebook.com/profile.php?id=100086756107731&mibextid=ZbWKwL"
                      className="text-light fs-4 me-4"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a
                      href="https://www.instagram.com/bestsolar.al/"
                      className="text-light fs-4 me-4"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </div>
                </div>
                <div className="pt-4">
                  <Link to="/form">
                    <button className="btn btn-primary-transparent btn-color-white btn-square fw-normal w-100 footerButton">
                      MERRNI KONSULENCË DHE OFERTË FALAS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <p className="fw-normal text-light">
              &copy; 2023 Best Solar Albania, All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
