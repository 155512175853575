import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import Map from "./Map";
function FormPage2(props) {
  const GKEY = "AIzaSyClhEC4DhecYRAyD3qKg7YXTmikgBI5Wx4";
  const {
    setPageNumber,
    cityAndZip,
    latitude,
    longitude,
    step,
    setStep,
    streetAddress,
    setStreetAddress,
    setpg2StreetAdress,
    setstreetLat,
    setstreetLng,
    setfoundStatus,
    setloadingInfoForm,
    setfinalLatitude,
    setfinalLongitude,
  } = props;
  const handleGoBackPage1 = (e) => {
    e.preventDefault();
    moveProgressBarPageBack();
    goToPage(1);
  };
  const handleContinueBtnClick_pg2 = (event) => {
    event.preventDefault();
    if (!streetAddress) {
      document.getElementById("streetAddressErrorMsg_pg2").style.display =
        "block";
    } else {
      document.getElementById("streetAddressErrorMsg_pg2").style.display =
        "none";
      moveProgressBarPageAhead();
      checkStreetAdress(streetAddress);
      setpg2StreetAdress(streetAddress);
      setStreetAddress("");
    }
  };
  function handleSubmitanyForm(e) {
    e.preventDefault();
  }
  function goToPage(newPageNumber) {
    setPageNumber(newPageNumber);
  }
  const TOTAL_PAGES = 7;
  function moveProgressBarPageAhead() {
    if (step < TOTAL_PAGES) {
      setStep(step + 1);
    }
  }
  function moveProgressBarPageBack() {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  const handleStreetAddressChange = (event) => {
    document.getElementById("streetAddressErrorMsg_pg2").style.display = "none";
    setStreetAddress(event.target.value);
  };
  const getLocationFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GKEY}`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const latitude = data.results[0].geometry.location.lat;
        const longitude = data.results[0].geometry.location.lng;
        return [latitude, longitude];
      } else {
        throw new Error(
          "Geocode was not successful for the following reason: " + data.status
        );
      }
    } catch (error) {
      throw new Error(
        "Error in getLocationFromAddress calling: https://maps.googleapis.com/maps/api/geocode/json" +
          error.message
      );
    }
  };

  async function checkStreetAdress(streetAddress) {
    try {
      const location = await getLocationFromAddress(streetAddress);
      const placeLocation = `${location[0]}, ${location[1]}`;
      setstreetLat(Number(location[0]));
      setstreetLng(Number(location[1]));
      moveProgressBarPageAhead();
      const lat = Number(location[0]);
      const lng = Number(location[1]);
      setfinalLatitude(lat);
      setfinalLongitude(lng);
      setloadingInfoForm("Po verifikojmë pozicionin");
      setfoundStatus(true);
      goToPage(3);
    } catch (error) {
      setfinalLatitude(latitude);
      setfinalLongitude(longitude);
      setfoundStatus(false);
      setpg2StreetAdress(cityAndZip);
      setloadingInfoForm("Adresa nuk u gjet. Lokalizoni çatinë manualisht!");
      moveProgressBarPageAhead();
      goToPage(3);
    }
  }
  return (
    <>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer p-0 "
        id="boxPage-2"
      >
        <Map latitude={latitude} longitude={longitude} />

        <div className="d-flex flex-column infoBox2">
          <span
            className="go-back align-self-start text-white fs-0 mb-2 fw-500"
            id="goBackLink_pg2"
            onClick={handleGoBackPage1}
          >
            <BsArrowLeft />
            Kthehu mbrapa
          </span>
          <h3 className="text-light text-center mb-3">Shkruaj adresën dhe lokalizo çatinë</h3>
          <form onSubmit={handleSubmitanyForm}>
            <div className="form-group d-flex flex-column">
              <div className="form-group mb-2">
                <input
                  className="form-control text-center"
                  type="text"
                  id="streetAddress"
                  placeholder="Adresa"
                  required
                  autoComplete="off"
                  value={streetAddress}
                  onChange={handleStreetAddressChange}
                />
                <small
                  id="streetAddressErrorMsg_pg2"
                  style={{ display: "none", color: "orangered" }}
                >
                  Ju lutem vendosni adresen e rruges
                </small>
              </div>
              <h5 className="text-white py-2" id="cityAndZip_pg2">
                {cityAndZip}
              </h5>
              <button
                className="btn btn-primary text-light fw-500"
                type="button"
                id="continueBtn_pg2"
                onClick={handleContinueBtnClick_pg2}
              >
                VAZHDO
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default FormPage2;
