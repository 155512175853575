import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact";
import Form from "./pages/Form/Form";
import Footer from "./components/Footer";
import Blog1HowToChooseTheRightCompany from "./pages/Blog/Blog1HowToChooseTheRightCompany";
import Blog2HowMuchEnergyProduces from "./pages/Blog/Blog2HowMuchEnergyProduces";
import Solarpanels from "./pages/Solarpanels";
import { BsArrowUpCircleFill } from "react-icons/bs";

function App() {
  const [buttonOpacity, setButtonOpacity] = useState(0);
   const [navOpacity, setNavOpacity] = useState(0);
     const stateProps = {navOpacity,setNavOpacity};
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const newOpacity = scrollPosition > 50 ? 1 : 0;
      setButtonOpacity(newOpacity);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setButtonOpacity(0);
  };

  
  return (
    <>
      <Router>
        <Navbar {...stateProps} />

        <section className="Navbar-position">
          <Routes>
            <Route path="/" element={<Home {...stateProps}/>} exact />
            <Route path="/about" element={<About {...stateProps} />} exact />
            <Route path="/blog" element={<Blog  {...stateProps} />} exact />
            <Route path="/contact" element={<Contact  {...stateProps} />} exact />
            <Route path="/form" element={<Form  {...stateProps} />} exact />
            <Route path="/solarpanels" element={<Solarpanels  {...stateProps} />} exact />
            <Route
              path="/Blog1HowToChooseTheRightCompany"
              element={<Blog1HowToChooseTheRightCompany  {...stateProps} />}
              exact
            />
            <Route
              path="/Blog2HowMuchEnergyProduces"
              element={<Blog2HowMuchEnergyProduces  {...stateProps} />}
              exact
            />
          </Routes>
        </section>
        <Footer />
      </Router>
      <a
        href="#"
        className="go-to-top-btn"
        id="go-to-top-btn"
        onClick={handleClick}
        style={{ opacity: buttonOpacity }}
      >
        <BsArrowUpCircleFill className="icon" />
      </a>
    </>
  );
}

export default App;
