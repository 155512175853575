import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsArrowLeft } from "react-icons/bs";
import "../../../../assets/css/style-react.css";

import { IoLocationSharp } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
const CircleProgressStatus = ({
  streetAddressInfo,
  loadingInfo,
  handleGoBackPage2,
  handleContinue_pg3,
  found,
}) => {
  const [progressValue, setProgressValue] = useState(0);
  const progressEndValue = 100;
  const speed = 50;
  const handleContinue_pg3fromCircleProgress = () => {
    handleContinue_pg3();
  };
  const handleGoBackPage2fromCircleprogress = () => {
    handleGoBackPage2();
  };
  useEffect(() => {
    const circularProgressBar = document.getElementById("circular-progress");
    circularProgressBar.style.display = "grid";

    const valueContainer = document.getElementById("value-container");

    let progress = setInterval(() => {
      setProgressValue((prevProgressValue) => {
        const updatedValue = prevProgressValue + 1;
        circularProgressBar.style.background = `conic-gradient(
          var(--primary-color) ${updatedValue * 3.6}deg,
          #cadcff ${updatedValue * 3.6}deg
        )`;
        if (updatedValue === progressEndValue) {
          valueContainer.innerHTML = "";
          const checkIcon = React.createElement(BsCheckLg);
          ReactDOM.render(checkIcon, valueContainer);
          clearInterval(progress);
        }
        return updatedValue;
      });
    }, speed);

    if (!found) {
      hideCircleProgressStatus();
    }

    return () => {
      clearInterval(progress);
    };
  }, []);

  const hideCircleProgressStatus = () => {
    const circularProgressBar = document.getElementById("circular-progress");
    circularProgressBar.style.display = "none";
  };

  return (
    <>
      <div className="d-flex flex-column infoBox2">
        <div className="d-flex justify-content-between align-items-center">
          <span
            className="go-back align-self-start text-white fs-6 mb-3 fw-500"
            id="goBackLink_pg3"
            onClick={handleGoBackPage2fromCircleprogress}
          >
            <BsArrowLeft />
            Kthehu mbrapa
          </span>
          <div className="circular-progress" id="circular-progress">
            <div className="value-container" id="value-container"></div>
          </div>
        </div>

        <h3 className="text-light text-center mb-3" id="loadingInfo_pg3">
          {loadingInfo}
        </h3>
        <p className="lead text-light fw-normal">
          <IoLocationSharp className="text-primary-col locationIconSize" />{" "}
          <span id="streetAddressInfo_pg3">{streetAddressInfo}</span>
        </p>
        <button
          className="btn btn-primary text-light fw-500"
          type="button"
          id="continueBtn_pg3"
          onClick={handleContinue_pg3fromCircleProgress}
        >
          VAZHDO
        </button>
      </div>
    </>
  );
};

export default CircleProgressStatus;
