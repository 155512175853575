import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
function FormPage6(props) {
  const {
    setPageNumber,
    step,
    setStep,
    billPrice,
    cityAndZip,
    customerType,
    finalLatitude,
    finalLongitude,
    pg2StreetAdress,
    sunCoverage,
  } = props;
  const [formName, setFormName] = useState("");
  const [formSurname, setFormSurname] = useState("");
  const [formRole, setFormRole] = useState("");
  const [formCompanyName, setFormCompanyName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formWarningText, setformWarningText] = useState(false);
  const [activeBorderInputname, setactiveBorderInputname] = useState(false);
  const [activeBorderInputsurname, setactiveBorderInputsurname] =
    useState(false);
  const [activeBorderInputrole, setactiveBorderInputrole] = useState(false);
  const [activeBorderInputcompanyname, setactiveBorderInputcompanyname] =
    useState(false);
  const [activeBorderInputemail, setactiveBorderInputemail] = useState(false);
  const [activeBorderInputphone, setactiveBorderInputphone] = useState(false);
  function goToPage(newPageNumber) {
    setPageNumber(newPageNumber);
  }
  const TOTAL_PAGES = 7;
  function moveProgressBarPageAhead() {
    if (step < TOTAL_PAGES) {
      setStep(step + 1);
    }
  }
  function moveProgressBarPageBack() {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  const handlegoBackpg5 = () => {
    moveProgressBarPageBack();
    setFormName("");
    setFormSurname("");
    setFormEmail("");
    setFormRole("");
    setFormCompanyName("");
    setFormPhone("");
    setformWarningText(false);
    goToPage(5);
  };
  function validateForm() {
    if (formName.trim() === "") {
      setFormName("");
      setactiveBorderInputname(true);
      return false;
    }
    if (formSurname.trim() === "") {
      setFormSurname("");
      setactiveBorderInputsurname(true);
      return false;
    }
    if (customerType === "BIZNES" && formRole.trim() === "") {
      setFormRole("");
      setactiveBorderInputrole(true);
      return false;
    }
    if (customerType === "BIZNES" && formCompanyName.trim() === "") {
      setFormCompanyName("");
      setactiveBorderInputcompanyname(true);
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formEmail.trim())) {
      setFormEmail("");
      setactiveBorderInputemail(true);
      return false;
    }
    if (formPhone.trim() === "") {
      setFormPhone("");
      setactiveBorderInputphone(true);
      return false;
    }
    return true;
  }
  function handleSubmitanyForm(e) {
    e.preventDefault();
  }
  function handleFormpg6Submit(e) {
    e.preventDefault();
    if (!validateForm()) {
      setformWarningText(true);
    } else {
      setactiveBorderInputname(false);
      setactiveBorderInputsurname(false);
      setactiveBorderInputrole(false);
      setactiveBorderInputcompanyname(false);
      setactiveBorderInputemail(false);
      setactiveBorderInputphone(false);
      setformWarningText(false);
      // sendEmail();
      moveProgressBarPageAhead();
      goToPage(7);
      console.log("AVG_PRICE: ", billPrice);
      console.log("CITY: ", cityAndZip);
      console.log("CUSTOMER_TYPE: ", customerType);
      console.log("LOCATION_LAT: ", finalLatitude);
      console.log("LOCATION_LONGITUDE: ", finalLongitude);
      console.log("STREET_ADRESS: ", pg2StreetAdress);
      console.log("SUN_COVERAGE: ", sunCoverage);
      console.log("        ");
      console.log("THE Following Is The User Adress");
      console.log("NAME: ", formName);
      console.log("FORM_SURNAME: ", formSurname);
      if (customerType === "BIZNES") {
        console.log("COMPANY_NAME: ", formCompanyName);
        console.log("ROLE: ", formRole);
      }
      console.log("EMAIL: ", formEmail);
      console.log("PHONE: ", formPhone);
    }
  }
  return (
    <>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer p-0 "
        id="boxPage-6"
      >
        <div className="d-flex flex-column infoBox2">
          <span
            className="go-back align-self-start text-white fs-0 mb-2 fw-500"
            id="goBackLink_pg6 "
            onClick={handlegoBackpg5}
          >
            <BsArrowLeft />
            Kthehu mbrapa
          </span>
          <h3 className="text-light text-center mb-3">
            Plotësoni të dhënat më poshtë
          </h3>
          <form onSubmit={handleSubmitanyForm} className="mb-2">
            <div className="d-flex mb-2">
              <div className="col pe-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Emri"
                  id="form-name"
                  autoComplete="off"
                  value={formName}
                  onChange={(e) => {
                    setFormName(e.target.value);
                    setformWarningText(false);
                    setactiveBorderInputname(false);
                  }}
                  style={
                    activeBorderInputname ? { border: "2px solid red" } : {}
                  }
                />
              </div>
              <div className="col ps-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mbiemri"
                  id="form-surname"
                  autoComplete="off"
                  value={formSurname}
                  onChange={(e) => {
                    setFormSurname(e.target.value);
                    setformWarningText(false);
                    setactiveBorderInputsurname(false);
                  }}
                  style={
                    activeBorderInputsurname ? { border: "2px solid red" } : {}
                  }
                />
              </div>
            </div>
            {customerType === "BIZNES" && (
              <div className="d-flex mb-0">
                <div className="col pe-1">
                  <input
                    type="text"
                    className="form-control mb-2 "
                    placeholder="Emri i Kompanisë"
                    id="form-companyName"
                    required
                    autoComplete="off"
                    value={formCompanyName}
                    onChange={(e) => {
                      setFormCompanyName(e.target.value);
                      setformWarningText(false);
                      setactiveBorderInputcompanyname(false);
                    }}
                    style={
                      activeBorderInputcompanyname
                        ? { border: "2px solid red" }
                        : {}
                    }
                  />
                </div>
                <div className="col ps-1">
                  <input
                    type="text"
                    className="form-control mb-2 "
                    placeholder="Roli"
                    id="form-role"
                    required
                    autoComplete="off"
                    value={formRole}
                    onChange={(e) => {
                      setFormRole(e.target.value);
                      setformWarningText(false);
                      setactiveBorderInputrole(false);
                    }}
                    style={
                      activeBorderInputrole ? { border: "2px solid red" } : {}
                    }
                  />
                </div>
              </div>
            )}
            <input
              type="email"
              className="form-control mb-2"
              placeholder="Email"
              id="form-email"
              required
              autoComplete="off"
              value={formEmail}
              onChange={(e) => {
                setFormEmail(e.target.value);
                setformWarningText(false);
                setactiveBorderInputemail(false);
              }}
              style={activeBorderInputemail ? { border: "2px solid red" } : {}}
            />
            <input
              type="tel"
              className="form-control"
              placeholder="Numri i telefonit"
              id="form-phone"
              autoComplete="off"
              value={formPhone}
              onChange={(e) => {
                setFormPhone(e.target.value);
                setformWarningText(false);
                setactiveBorderInputphone(false);
              }}
              style={activeBorderInputphone ? { border: "2px solid red" } : {}}
            />
          </form>
          {formWarningText && (
            <span className="text-primary-col fw-500 my-2 " id="form-warning">
              Ju lutem plotësoni të gjitha fushat!
            </span>
          )}
          <button
            className="btn btn-primary text-light fw-500"
            type="submit"
            id="submitBtn_pg6"
            onClick={handleFormpg6Submit}
          >
            DËRGO
          </button>
        </div>
      </div>
    </>
  );
}

export default FormPage6;
