import { useState, useEffect } from "react";

function Map({ latitude, longitude }) {
  const [map, setMap] = useState(null);
  const GKEY = "AIzaSyClhEC4DhecYRAyD3qKg7YXTmikgBI5Wx4";
  const removeScript = (src) => {
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute("src") === src) {
        document.head.removeChild(scripts[i]);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const onLoad = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: latitude, lng: longitude },
        zoom: 13,
      });

      const marker = new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
      });

      setMap(map);
    };

    if (!window.google) {
    } else {
      onLoad();
    }

    return () => {
      if (map) {
        map.setMap(null);
      }
    };
  }, [latitude, longitude]);

  return <div id="map" style={{ height: "200px" }} />;
}

export default Map;
