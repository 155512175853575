import React,{useState} from "react";
import {IoLocationSharp} from "react-icons/io5";
const albanianCities=[
  {city: "Tirana",lat: "41.3300",lng: "19.8200",postalCode: "1001"},
  {city: "Durrës",lat: "41.3167",lng: "19.4500",postalCode: "2001"},
  {city: "Vlorë",lat: "40.4500",lng: "19.4833",postalCode: "9401"},
  {city: "Kamëz",lat: "41.3833",lng: "19.7667",postalCode: "1030"},
  {city: "Fier",lat: "40.7167",lng: "19.5500",postalCode: "9301"},
  {city: "Shkodër",lat: "42.0667",lng: "19.5000",postalCode: "4001"},
  {city: "Elbasan",lat: "41.1125",lng: "20.0822",postalCode: "3001"},
  {city: "Korçë",lat: "40.6167",lng: "20.7667",postalCode: "7001"},
  {city: "Sarandë",lat: "39.8833",lng: "20.0167",postalCode: "9701"},
  {city: "Berat",lat: "40.7049",lng: "19.9497",postalCode: "5001"},
  {city: "Lushnjë",lat: "40.9333",lng: "19.7000",postalCode: "9001"},
  {city: "Kavajë",lat: "41.1833",lng: "19.5500",postalCode: "1004"},
  {city: "Gjirokastër",lat: "40.0758",lng: "20.1389",postalCode: "6001"},
  {city: "Pogradec",lat: "40.9000",lng: "20.6500",postalCode: "8301"},
  {city: "Fushë-Krujë",lat: "41.4833",lng: "19.7167",postalCode: "1502"},
  {city: "Laç",lat: "41.6353",lng: "19.7131",postalCode: "4201"},
  {city: "Kukës",lat: "42.0758",lng: "20.4231",postalCode: "8501"},
  {city: "Sukth",lat: "41.3833",lng: "19.5333",postalCode: "2010"},
  {city: "Lezhë",lat: "41.7805",lng: "19.6434",postalCode: "4501"},
  {city: "Patos",lat: "40.6841",lng: "19.6194",postalCode: "9307"},
  {city: "Peshkopi",lat: "41.6831",lng: "20.4289",postalCode: "8301"},
  {city: "Librazhd",lat: "41.1969",lng: "20.3356",postalCode: "3401"},
  {city: "Kuçovë",lat: "40.8039",lng: "19.9144",postalCode: "5301"},
  {city: "Krujë",lat: "41.5178",lng: "19.7978",postalCode: "1501"},
  {city: "Vorë",lat: "41.3939",lng: "19.6544",postalCode: "1032"},
  {city: "Burrel",lat: "41.6000",lng: "20.0000",postalCode: "3201"},
  {city: "Libonik",lat: "40.7500",lng: "20.7167",postalCode: "7021"},
  {city: "Rrëshen",lat: "41.7667",lng: "19.8833",postalCode: "4601"},
  {city: "Belsh",lat: "40.9833",lng: "19.8833",postalCode: "3008"},
  {city: "Divjakë",lat: "40.9964",lng: "19.5327",postalCode: "9022"},
  {city: "Gramsh",lat: "40.8667",lng: "20.1833",postalCode: "3301"},
  {city: "Mamurras",lat: "41.5775",lng: "19.6922",postalCode: "4704"},
  {city: "Bulqizë",lat: "41.5000",lng: "20.2167",postalCode: "8402"},
  {city: "Vau i Dejës",lat: "42.0000",lng: "19.6333",postalCode: "4008"},
  {city: "Shëngjin",lat: "41.8136",lng: "19.5939",postalCode: "4503"},
  {city: "Ballsh",lat: "40.5991",lng: "19.7359",postalCode: "9308"},
  {city: "Shijak",lat: "41.3456",lng: "19.5672",postalCode: "2013"},
  {city: "Bilisht",lat: "40.6275",lng: "20.9900",postalCode: "7006"},
  {city: "Rrogozhinë",lat: "41.0761",lng: "19.6667",postalCode: "2503"},
  {city: "Librazhd",lat: "41.1833",lng: "20.3167",postalCode: "3401"},
  {city: "Cërrik",lat: "41.0333",lng: "19.9833",postalCode: "3007"},
  {city: "Roskovec",lat: "40.7375",lng: "19.7022",postalCode: "9306"},
  {city: "Peqin",lat: "41.0500",lng: "19.7500",postalCode: "3501"},
  {city: "Krumë",lat: "42.1961",lng: "20.4146",postalCode: "8602"},
  {city: "Përmet",lat: "40.2333",lng: "20.3500",postalCode: "6401"},
  {city: "Prrenjas",lat: "41.0667",lng: "20.5500",postalCode: "3403"},
  {city: "Delvinë",lat: "39.9494",lng: "20.0958",postalCode: "9704"},
  {
    city: "Bajram Curri",
    lat: "42.3582",
    lng: "20.0760",
    postalCode: "8702",
  },
  {
    city: "Ura Vajgurore",
    lat: "40.7689",
    lng: "19.8778",
    postalCode: "5007",
  },
  // { "city": "Rubik", "lat": "41.7667", "lng": "19.7833", "postalCode": ""// },
  {city: "Tepelenë",lat: "40.3000",lng: "20.0167",postalCode: "6401"},
  {city: "Poliçan",lat: "40.6122",lng: "20.0981",postalCode: "5403"},
  {city: "Maliq",lat: "40.7083",lng: "20.7000",postalCode: "7005"},
  {city: "Çorovodë",lat: "40.5042",lng: "20.2272",postalCode: "5401"},
  {city: "Ersekë",lat: "40.3333",lng: "20.6833",postalCode: "7401"},
  {city: "Koplik",lat: "42.2136",lng: "19.4364",postalCode: "4301"},
  {city: "Pukë",lat: "42.0500",lng: "19.9000",postalCode: "4401"},
  {city: "Lazarat",lat: "40.0500",lng: "20.1500",postalCode: "6004"},
  {city: "Memaliaj",lat: "40.3500",lng: "19.9833",postalCode: "6302"},
];
function FormPage1(props) {
  const [filteredCities,setFilteredCities]=useState([]);
  const [cityResultVisible,setCityResultVisible]=useState(false);
  const [errorMsgVisible,setErrorMsgVisible]=useState(false);
  const GKEY="AIzaSyClhEC4DhecYRAyD3qKg7YXTmikgBI5Wx4";
  const {
    setPageNumber,
    setCityAndZip,
    setlatitude,
    setlongitude,
    step,
    setStep,
    cityOrZip,
    setCityOrZip,
    billPrice,
    setBillPrice,
  }=props;
  function handleSubmitanyForm(e) {
    e.preventDefault();
  }
  const handlePriceChange=(event) => {
    setBillPrice(event.target.value*10000);
  };

  function handleCityOrZipChange(e) {
    setErrorMsgVisible(false);
    const {value}=e.target;
    setCityOrZip(value);
    const filteredCitiesArray=albanianCities.filter((c) => {
      return (
        c.city.toLowerCase().includes(value.toLowerCase())||
        c.postalCode.includes(value)
      );
    });
    setFilteredCities(filteredCitiesArray);

    if(value.trim().length>0) {
      if(filteredCitiesArray.length>4) {
        setCityResultVisible(true);
      } else if(filteredCitiesArray.length>=1) {
        setCityResultVisible(true);
      } else {
        setCityResultVisible(false);
      }
    } else {
      setCityResultVisible(false);
      setErrorMsgVisible(false);
    }
  }
  const handleCityClick=(e) => {
    setCityOrZip(e.target.innerText);
    setCityResultVisible(false);
  };
  const handleClickcontinueBtn_pg1=async (e) => {
    e.preventDefault();
    const correctCity=albanianCities.some((c) => c.city===cityOrZip.trim());
    if(cityOrZip.trim()===""||!correctCity) {
      setErrorMsgVisible(true);
    } else {
      let city=cityOrZip+", Albania";
      let latLong=await getLocationFromAddress(city);
      setlatitude(latLong[0]);
      setlongitude(latLong[1]);
      setErrorMsgVisible(false);
      setCityAndZip(cityOrZip);
      setCityOrZip("");
      moveProgressBarPageAhead();
      goToPage(2);
    }
  };
  function goToPage(newPageNumber) {
    setPageNumber(newPageNumber);
  }
  const TOTAL_PAGES=7;
  function moveProgressBarPageAhead() {
    if(step<TOTAL_PAGES) {
      setStep(step+1);
    }
  }

  const getLocationFromAddress=async (address) => {
    try {
      const response=await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GKEY}`
      );
      const data=await response.json();
      if(data.status==="OK") {
        const latitude=data.results[0].geometry.location.lat;
        const longitude=data.results[0].geometry.location.lng;
        return [latitude,longitude];
      } else {
        throw new Error(
          "Geocode was not successful for the following reason: "+data.status
        );
      }
    } catch(error) {
      throw new Error(
        "Error in getLocationFromAddress calling: https://maps.googleapis.com/maps/api/geocode/json"+
        error.message
      );
    }
  };
  return (
    <>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer"
        id="boxPage-1"
      >
        <h2 className="text-light text-center mb-3">
          Zbuloni sa kurseni duke instaluar panelet fotovoltaike.
        </h2>
        <h5 className="text-primary-col">
          Merrni konsulencë dhe një ofertë falas
        </h5>
        <form onSubmit={handleSubmitanyForm}>
          <div className="form-group d-flex flex-column">
            <label
              htmlFor="price-range"
              className="form-label text-light text-center lead w-100 fw-normal"
            >
              Sa paguani mesatarisht në muaj për energjinë elektrike?
            </label>
            <div className="per-month text-light mb-2">
              <span id="billPrice_pg1">
                {billPrice.toLocaleString("en-US")} Lek/muaj
              </span>
            </div>
            <input
              type="range"
              className="form-range custom-range text-primary-col"
              min="1"
              max="20"
              id="price-range"
              value={billPrice/10000}
              onChange={handlePriceChange}
            />
            {/* <input type="text" className="form-control" id="price-range"    onChange={handlePriceChange} style={{textAlign: 'center'}} /> */}



            <div className="d-flex justify-content-between">
              <p className="text-light">5.000 Lek</p>
              <p className="text-light">Më shumë se 200.000 Lek</p>
              {/* <p className="text-light twoHunderedLek" onClick={() => document.getElementById("price-range").focus()}>Më shumë se 200.000 Lek</p> */}

            </div>
            <div className="form-group mb-2 position-relative">
              <input
                className="form-control text-center "
                type="text"
                id="cityOrZip_pg1"
                placeholder="Vendos qytetin"
                required
                autoComplete="off"
                value={cityOrZip}

                onChange={handleCityOrZipChange}
                style={{
                  borderColor: errorMsgVisible? "orangered":"",
                }}
              />
              {cityResultVisible&&(
                <div className="city-result" id="cityResult_pg2">
                  <ul id="resultCityZip_pg1">
                    {filteredCities.map((city,index) => (
                      <li key={index} onClick={handleCityClick}>
                        {/* <i className="fa-solid fa-location-dot text-primary-col"></i> */}
                        <IoLocationSharp className="text-primary-col locationIconSize" />
                        {city.city}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {errorMsgVisible&&(
                <small
                  id="cityOrZipErrorMsg_pg1"
                  style={{color: "orangered"}}
                >
                  Ju lutem vendosni një qytet ose kod postal.
                </small>
              )}
            </div>
            <button
              className="btn btn-primary text-light fw-500"
              type="submit"
              id="continueBtn_pg1"
              onClick={handleClickcontinueBtn_pg1}
            >
              LLOGARIT KURSIM
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default FormPage1;
