import React, { useState, useEffect } from "react";
import blog2_mainhowmuchenergy from "../../assets/img/blog-2/blog2_main_how-much-energy.png";
import blog2_mainlamp from "../../assets/img/blog-2/blog2_main_lamp.png";
import solarenergycalculation from "../../assets/img/blog-2/Solar-energy-calculation.png";
import sapanelduhen from "../../assets/img/blog-2/sa_panele_duhen.jpg";
import "../../assets/css/style.css";
import "../../assets/css/style-react.css";
import { Link } from "react-router-dom";
function Blog2HowMuchEnergyProduces(props) {
  const {navOpacity,setNavOpacity}=props;
  useEffect(() => {
  setNavOpacity(1)
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Preload images
  useEffect(() => {
    new Image().src = blog2_mainhowmuchenergy;
    new Image().src = blog2_mainlamp;
  }, []);

  function handlePrevClick() {
    setCurrentSlide(currentSlide === 0 ? 1 : currentSlide - 1);
  }

  function handleNextClick() {
    setCurrentSlide(currentSlide === 1 ? 0 : currentSlide + 1);
  }
  console.log(`currentSlide: ${currentSlide}`);
  return (
    <>
      <section className="bg-light blog1Section-1">
        <div className="container py-5 blog-page-content blog-title-container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h1 className="display-4 mb-5 fw-normal text-center">
                Sa energji prodhojnë panelet fotovoltaike?
              </h1>
              <p>
                Një panel fotovoltaik prodhon rreth 400 wat për një orë,
                afërsisht 600 kilowat në vit, të njëjtën energji që harxhon
                mesatarisht një shtëpi gjatë një muaji.{" "}
              </p>
              <p>
                Në këto kushte, kthimi i investimit është I shkëlqyer pasi kosto
                e paneleve diellore ka rënë ndjeshëm në dekadat e fundit kështu
                që ato janë bërë më të përballueshme se kurrë më parë.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 pb-5 mx-auto">
              <div className="blog-card-wrapper2">
                <h4 className="mb-3 headingblog1colorchanging">
                  Panelet diellore
                </h4>
                <div className="card">
                  <div className="blog-2crousel">
                    <div className="carousel-images">
                      <img
                        src={blog2_mainhowmuchenergy}
                        alt="Slide 1"
                        className={`carousel-image ${
                          currentSlide === 0 ? "active" : ""
                        }`}
                      />
                      <img
                        src={blog2_mainlamp}
                        alt="Slide 2"
                        className={`carousel-image ${
                          currentSlide === 1 ? "active" : ""
                        }`}
                      />
                    </div>
                    <div className="carousel-buttons">
                      <button
                        onClick={handlePrevClick}
                        className="prev"
                      ></button>
                      <button
                        onClick={handleNextClick}
                        className="next"
                      ></button>
                    </div>
                  </div>

                  <div className="blog-body">
                    <div className="post-content position-relative card-body">
                      <p>
                        Sasia e energjisë së prodhuar varet nga lloji I panelit
                        që do tw përdorni. Panelet diellore ndahen në dy lloje:
                      </p>
                      <p>
                        I pari është ai me rrymë të vazhduar (DC). Ai punon duke
                        shndërruar energjinë diellore në rrymë të vazhduar.
                        Panelet DC janë më më të zakonshmit sot, sidomos ata të
                        montuar në cati, pasi punojnë në mënyrë të pavarur, pa
                        pasur nevojë për inverter.
                      </p>
                      <p>
                        Lloji I dytë është ai me rrymë alternative (AC) që punon
                        duke shndërruar energjinë e diellit në rrymë
                        alternative. Panelet AC janë më efikas se panelet DC por
                        nuk mund të punojnë më vete, por duan inverter për t’u
                        shfrytëzuar.
                      </p>
                      <p>
                        Efikasisteti I paneleve diellore varet nga lloji I
                        panelit që përdorni dhe ndricimit që ata marrin.
                      </p>
                      <div className="pt-2 mb-4">
                        <h4 className="mb-3 headingblog1colorchangingy">
                          Vendndodhja
                        </h4>
                        <p>
                          Gjithashtu, vendndodhja e panelit ndikon në sasinë e
                          energjisë që mund të prodhojë. Për shembull: në disa
                          zona, dielli është më I fortë se në disa të tjera.
                          Gjithashtu, nuk është mirë të vendosen shumë larg nga
                          objekti/shtëpia juaj pasi do të kishte humbje
                          energjie!
                        </p>
                        <p>
                          Si përfundim, relievi I mjedisit ku është vendosur
                          objekti/shtëpia juaj do të ketë një efekt të madh në
                          sasinë e energjisë që do të prodhojë paneli me kalimin
                          e kohës.{" "}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h4 className="mb-3 headingblog1colorchanging">
                          Si llogaritet energjia diellore?
                        </h4>
                        <img
                          src={solarenergycalculation}
                          className="img-fluid mb-3 pt-1"
                          alt=""
                        />
                        <p>
                          Sasia e energjisë që prodhohet nga një panel diellor
                          mund të llogaritet me anë të formulës së mëposhtme:
                        </p>
                        <p>
                          <strong>Energji = Fuqi x Kohë</strong>
                        </p>
                        <p>
                          Ku fuqia jepet ne watt, koha në orë, dhe energjia
                          jepet në watt orë.
                        </p>
                      </div>
                      <div className="mb-3">
                        <h4 className="mb-3 headingblog1colorchanging">
                          Sa panele diellore duhen për të furnizuar
                          objektin/shtëpinë time?
                        </h4>
                        <p>
                          Për të zbuluar këtë duhet të bëni dy llogaritje. Së
                          pari, duhet të gjeni konsumin e biznesit apo shtëpisë
                          tuaj. Një panel me kapacitet 400 watt do të prodhojë
                          një tension 50 volt me një rrymë 8 amper (400 watt =
                          50 volt x 8 amper). Këtë duhet ta bëni për cdo panel
                          në sistemin tuaj.
                        </p>
                        <p>
                          Më pas duhet te shumëzoni këtë fuqi me numrin e orëve.
                          Sistemi juaj do të ekspozohet në diell mesatarisht 6
                          orë në ditë.
                        </p>
                        <p>
                          Për të furnizuar shtëpinë me panele fotovoltaike, do
                          ju duheshin atëherë rreth 10 panele duke patur
                          parasysh se një shtëpi e zakonshme konsumon rreth 8000
                          Kw/orë.
                        </p>
                        <p>
                          Për të patur një llogaritje të saktë për sasinë e
                          paneleve që duhet të instaloni për biznesin apo
                          shtëpinë tuaj, <Link to="/">Na kontaktoni</Link>, ose{" "}
                          <Link to="/form">plotësoni formularin</Link>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog2HowMuchEnergyProduces;
