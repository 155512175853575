import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/style-react.css";
import "bootstrap/dist/css/bootstrap.css";
import navLogo from "../assets/img/navbar-brand-logo.png";

function Navbar(props) {
  const contactUsSectionRef = useRef(null);
  // const [navOpacity, setNavOpacity] = useState(0);
  const {navOpacity,setNavOpacity}=props;

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     const newOpacity = scrollPosition > 20 ? 1 : 0;
  //     setNavOpacity(newOpacity);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");
    navLinks.forEach((link) => {
      link.style.setProperty(
        "--nav-link-color",
        navOpacity ? "black" : "white"
      );
    });
  }, [navOpacity]);
  function handleContactClick() {
    contactUsSectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const [isToggled, setIsToggled] = useState(false);
  useEffect(() => {
    console.log("nav", navOpacity);
  }, [navOpacity]);
  function handleToggle(e) {
    setIsToggled(!isToggled);
  }

  return (
    <section className="position-relative">
      <nav
        className="navbar navbar-expand-lg bg-lg-transparent bg-lg-light py-1 fixed-top"
        id="navbar"
        style={{ background: navOpacity ? "white" : "none" }}
      >
        <div
          className="container-xl px-xs-4 px-sm-4 px-md-5 px-lg-5 px-xl-5 px-5a"
          id="navbar-sm"
        >
          <div
            className="d-flex flex-column nav-logo  navLogo"
            style={{ width: "85px" }}
          >
            <Link
              to="/"
              className="navbar-brand mx-auto"
              style={{ width: "45px" }}
            >
              <img src={navLogo} className="img-fluid" alt="" />
            </Link>
            <p
              className="mb-0 text-center logo-content"
              style={{ color: navOpacity ? "black" : "white" }}
            >
              BEST SOLAR
            </p>
            <p
              className="mb-0 text-center logo-content small-p"
              style={{ color: navOpacity ? "black" : "white" }}
            >
              Investo aq sa duhet
            </p>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
            aria-expanded="true"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`navbar-collapse text-center ${isToggled ? "open" : ""}`}
            id="navmenu"
          >
            <ul className="navbar-nav ms-auto align-items-center smoothopen ">
              <li className="nav-item">
                <Link to="/about" className="nav-link " onClick={handleToggle}>
                  Rreth Nesh
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/solarpanels"
                  className="nav-link text-white"
                  onClick={handleToggle}
                >
                  Panelet Fotovoltaike
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/blog"
                  className="nav-link text-white"
                  onClick={handleToggle}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className="nav-link text-white"
                  onClick={handleToggle}
                >
                  Na kontaktoni
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/form"
                  className="nav-link btn btn-primary btn-sm text-white rounded"
                  onClick={handleToggle}
                >
                  Merrni konsulencë dhe një ofertë fala
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Navbar;
