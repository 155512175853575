import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/style.css";
import "../assets/css/style-react.css";
function Contact(props) {
  const { navOpacity, setNavOpacity } = props;
  useEffect(() => {
    setNavOpacity(1);
  }, []);
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formquestion, setFormquestion] = useState("");
  const [formWarningText, setformWarningText] = useState(false);
  const [activeBorderInputname, setactiveBorderInputname] = useState(false);
  const [activeBorderInputquestion, setactiveBorderInputquestion] =
    useState(false);
  const [activeBorderInputemail, setactiveBorderInputemail] = useState(false);
  const [activeBorderInputphone, setactiveBorderInputphone] = useState(false);
  function validateForm() {
    if (formName.trim() === "") {
      setFormName("");
      setactiveBorderInputname(true);
      return false;
    }

    if (formquestion.trim() === "") {
      setFormquestion("");
      setactiveBorderInputquestion(true);
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formEmail.trim())) {
      setFormEmail("");
      setactiveBorderInputemail(true);
      return false;
    }
    const regex = /^\d+$/;
    if (!regex.test(formPhone.trim())) {
      setFormPhone("");
      setactiveBorderInputphone(true);
      return false;
    }
    return true;
  }
  function handleSubmitanyForm(e) {
    e.preventDefault();
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (!validateForm()) {
      setformWarningText(true);
    } else {
      setactiveBorderInputname(false);

      setactiveBorderInputquestion(false);
      setactiveBorderInputemail(false);
      setactiveBorderInputphone(false);
      setformWarningText(false);

      console.log("THE Following Is The User Adress");
      console.log("NAME: ", formName);
      console.log("EMAIL: ", formEmail);
      console.log("PHONE: ", formPhone);
      console.log("QUESTION: ", formquestion);
    }
  }
  return (
    <>
      <section id="contactUsSection" className="bg-navy py-5">
        <div className="container-lg steps-to-energy">
          <div className="text-center py-3">
            <h2 className="text-primary-col">
              <span className="caveat-text">
                Jeni një biznes që konsumon shumë energji?
              </span>
            </h2>
            <h3 className="text-light fw-bold steps-heading pt-1 pb-4">
              Na kontaktoni per të zbuluar sa mund të kurseni!
            </h3>
            <p className="lead text-light fw-normal">
              <span className="text-primary-col fw-500">
                <a href="./pages/formBox.html" className="text-primary-col">
                  Plotësoni formularin
                </a>
              </span>{" "}
              ose dërgoni pyetjen duke plotësuar formin më poshtë. Në të kundërt
              mund të dërgoni një email në{" "}
              <a
                href="mailto:info@bestsolar.al"
                style={{ color: "white" }}
                className="contacthov"
                onMouseEnter={(e) =>
                  (e.target.style.color = "var(--primary-color)")
                }
                onMouseLeave={(e) => (e.target.style.color = "white")}
              >
                info@bestsolar.al
              </a>
            </p>
          </div>
          <form
            className="home-form mx-auto px-4"
            onSubmit={handleSubmitanyForm}
          >
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                id="name"
                placeholder="Emri juaj"
                autoComplete="off"
                value={formName}
                onChange={(e) => {
                  setFormName(e.target.value);
                  setformWarningText(false);
                  setactiveBorderInputname(false);
                }}
                style={activeBorderInputname ? { border: "2px solid red" } : {}}
              />
              {activeBorderInputname && (
                <span className="text-danger">
                  Ju lutem shkruani emrin tuaj.
                </span>
              )}
            </div>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                id="phone"
                placeholder="Telefon"
                autoComplete="off"
                value={formPhone}
                onChange={(e) => {
                  setFormPhone(e.target.value);
                  setformWarningText(false);
                  setactiveBorderInputphone(false);
                }}
                style={
                  activeBorderInputphone ? { border: "2px solid red" } : {}
                }
              />
              {activeBorderInputphone && (
                <span className="text-danger">
                  Ju lutem shkruani numrin tuaj të telefonit.
                </span>
              )}
            </div>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                id="email"
                placeholder="Email"
                autoComplete="off"
                value={formEmail}
                onChange={(e) => {
                  setFormEmail(e.target.value);
                  setformWarningText(false);
                  setactiveBorderInputemail(false);
                }}
                style={
                  activeBorderInputemail ? { border: "2px solid red" } : {}
                }
              />
              {activeBorderInputemail && (
                <span className="text-danger">
                  Ju lutem shkruani një email të vlefshëm.
                </span>
              )}
            </div>
            <div className="form-group">
              <textarea
                className="form-control form-control-lg"
                type="number"
                id="question"
                placeholder="Pyetja juaj"
                rows="3"
                cols="50"
                autoComplete="off"
                value={formquestion}
                onChange={(e) => {
                  setFormquestion(e.target.value);
                  setformWarningText(false);
                  setactiveBorderInputquestion(false);
                }}
                style={
                  activeBorderInputquestion ? { border: "2px solid red" } : {}
                }
              ></textarea>
            </div>
            <button
              className="btn btn-primary btn-square fw-bold fs-5 w-100"
              type="submit"
              onClick={handleSubmit}
            >
              Dërgo
            </button>
            <div className="contact-us-privacy">
              <p className="mx-auto">
                <FontAwesomeIcon icon={faLock} className="pe-1" />
                Ne e vlerësojmë privatësinë tuaj. Informacioni juaj është 100% I
                sigurtë. Ne nuk e shpërndajmë apo shpërdorojmë atë.{" "}
              </p>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contact;
