import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { BiMobileAlt } from "react-icons/bi";
import { BiFile } from "react-icons/bi";
import { BiBrightness } from "react-icons/bi";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/style-react.css";
import imgsakurseni1 from "../assets/img/perse_investioni_1_sa_kurseni.jpg";
import imgrritvleren2 from "../assets/img/perse_investioni_2_rrit-vleren.jpg";
import imgfinancimipaneleve3 from "../assets/img/perse_investioni_3_financimi_paneleve_diellore.jpg";
import blog1Img from "../assets/img/blog-1/blog1_main.png";
import blog2Img from "../assets/img/blog-2/blog2_main_how-much-energy.png";
import vdo from "../assets/media/Smart-energy-USA.mp4";
import loyalityimg from "../assets/img/icons/lineal/loyalty.svg";
import adjustimg from "../assets/img/icons/lineal/adjust.svg";
import telemimg from "../assets/img/icons/lineal/telemarketer.svg";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";
function Home(props) {
  const { navOpacity, setNavOpacity } = props;
  const navigate = useNavigate();
  const albanianCities = [
    { city: "Tirana", lat: "41.3300", lng: "19.8200", postalCode: "1001" },
    { city: "Durrës", lat: "41.3167", lng: "19.4500", postalCode: "2001" },
    { city: "Vlorë", lat: "40.4500", lng: "19.4833", postalCode: "9401" },
    { city: "Kamëz", lat: "41.3833", lng: "19.7667", postalCode: "1030" },
    { city: "Fier", lat: "40.7167", lng: "19.5500", postalCode: "9301" },
    { city: "Shkodër", lat: "42.0667", lng: "19.5000", postalCode: "4001" },
    { city: "Elbasan", lat: "41.1125", lng: "20.0822", postalCode: "3001" },
    { city: "Korçë", lat: "40.6167", lng: "20.7667", postalCode: "7001" },
    { city: "Sarandë", lat: "39.8833", lng: "20.0167", postalCode: "9701" },
    { city: "Berat", lat: "40.7049", lng: "19.9497", postalCode: "5001" },
    { city: "Lushnjë", lat: "40.9333", lng: "19.7000", postalCode: "9001" },
    { city: "Kavajë", lat: "41.1833", lng: "19.5500", postalCode: "1004" },
    { city: "Gjirokastër", lat: "40.0758", lng: "20.1389", postalCode: "6001" },
    { city: "Pogradec", lat: "40.9000", lng: "20.6500", postalCode: "8301" },
    { city: "Fushë-Krujë", lat: "41.4833", lng: "19.7167", postalCode: "1502" },
    { city: "Laç", lat: "41.6353", lng: "19.7131", postalCode: "4201" },
    { city: "Kukës", lat: "42.0758", lng: "20.4231", postalCode: "8501" },
    { city: "Sukth", lat: "41.3833", lng: "19.5333", postalCode: "2010" },
    { city: "Lezhë", lat: "41.7805", lng: "19.6434", postalCode: "4501" },
    { city: "Patos", lat: "40.6841", lng: "19.6194", postalCode: "9307" },
    { city: "Peshkopi", lat: "41.6831", lng: "20.4289", postalCode: "8301" },
    { city: "Librazhd", lat: "41.1969", lng: "20.3356", postalCode: "3401" },
    { city: "Kuçovë", lat: "40.8039", lng: "19.9144", postalCode: "5301" },
    { city: "Krujë", lat: "41.5178", lng: "19.7978", postalCode: "1501" },
    { city: "Vorë", lat: "41.3939", lng: "19.6544", postalCode: "1032" },
    { city: "Burrel", lat: "41.6000", lng: "20.0000", postalCode: "3201" },
    { city: "Libonik", lat: "40.7500", lng: "20.7167", postalCode: "7021" },
    { city: "Rrëshen", lat: "41.7667", lng: "19.8833", postalCode: "4601" },
    { city: "Belsh", lat: "40.9833", lng: "19.8833", postalCode: "3008" },
    { city: "Divjakë", lat: "40.9964", lng: "19.5327", postalCode: "9022" },
    { city: "Gramsh", lat: "40.8667", lng: "20.1833", postalCode: "3301" },
    { city: "Mamurras", lat: "41.5775", lng: "19.6922", postalCode: "4704" },
    { city: "Bulqizë", lat: "41.5000", lng: "20.2167", postalCode: "8402" },
    { city: "Vau i Dejës", lat: "42.0000", lng: "19.6333", postalCode: "4008" },
    { city: "Shëngjin", lat: "41.8136", lng: "19.5939", postalCode: "4503" },
    { city: "Ballsh", lat: "40.5991", lng: "19.7359", postalCode: "9308" },
    { city: "Shijak", lat: "41.3456", lng: "19.5672", postalCode: "2013" },
    { city: "Bilisht", lat: "40.6275", lng: "20.9900", postalCode: "7006" },
    { city: "Rrogozhinë", lat: "41.0761", lng: "19.6667", postalCode: "2503" },
    { city: "Librazhd", lat: "41.1833", lng: "20.3167", postalCode: "3401" },
    { city: "Cërrik", lat: "41.0333", lng: "19.9833", postalCode: "3007" },
    { city: "Roskovec", lat: "40.7375", lng: "19.7022", postalCode: "9306" },
    { city: "Peqin", lat: "41.0500", lng: "19.7500", postalCode: "3501" },
    { city: "Krumë", lat: "42.1961", lng: "20.4146", postalCode: "8602" },
    { city: "Përmet", lat: "40.2333", lng: "20.3500", postalCode: "6401" },
    { city: "Prrenjas", lat: "41.0667", lng: "20.5500", postalCode: "3403" },
    { city: "Delvinë", lat: "39.9494", lng: "20.0958", postalCode: "9704" },
    {
      city: "Bajram Curri",
      lat: "42.3582",
      lng: "20.0760",
      postalCode: "8702",
    },
    {
      city: "Ura Vajgurore",
      lat: "40.7689",
      lng: "19.8778",
      postalCode: "5007",
    },
    // { "city": "Rubik", "lat": "41.7667", "lng": "19.7833", "postalCode": ""// },
    { city: "Tepelenë", lat: "40.3000", lng: "20.0167", postalCode: "6401" },
    { city: "Poliçan", lat: "40.6122", lng: "20.0981", postalCode: "5403" },
    { city: "Maliq", lat: "40.7083", lng: "20.7000", postalCode: "7005" },
    { city: "Çorovodë", lat: "40.5042", lng: "20.2272", postalCode: "5401" },
    { city: "Ersekë", lat: "40.3333", lng: "20.6833", postalCode: "7401" },
    { city: "Koplik", lat: "42.2136", lng: "19.4364", postalCode: "4301" },
    { city: "Pukë", lat: "42.0500", lng: "19.9000", postalCode: "4401" },
    { city: "Lazarat", lat: "40.0500", lng: "20.1500", postalCode: "6004" },
    { city: "Memaliaj", lat: "40.3500", lng: "19.9833", postalCode: "6302" },
  ];
  const [filteredCities, setFilteredCities] = useState([]);
  const [errorMsgVisible, setErrorMsgVisible] = useState(false);
  const [cityResultVisible, setCityResultVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  function handleSubmitanyForm(e) {
    e.preventDefault();
  }
  const handlePriceChange = (event) => {
    setBillPrice(event.target.value * 10000);
  };
  const handleCityClick = (e) => {
    setCityOrZip(e.target.innerText);
    setCityResultVisible(false);
  };
  // Preload images
  useEffect(() => {
    new Image().src = imgsakurseni1;
    new Image().src = imgrritvleren2;
    new Image().src = imgfinancimipaneleve3;
    new Image().src = blog1Img;
    new Image().src = blog2Img;

    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition <= 0 ? 0 : currentPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [billPrice, setBillPrice] = useState(100000);
  const [cityOrZip, setCityOrZip] = useState("");

  const handlePriceRangeChange = (event) => {
    setBillPrice(event.target.value * 10000);
  };

  const handleCityOrZipChange = (e) => {
    setErrorMsgVisible(false);
    const { value } = e.target;
    setCityOrZip(value);
    const filteredCitiesArray = albanianCities.filter((c) => {
      return (
        c.city.toLowerCase().includes(value.toLowerCase()) ||
        c.postalCode.includes(value)
      );
    });
    setFilteredCities(filteredCitiesArray);
    console.log(value);
    if (value.trim().length > 0) {
      if (filteredCitiesArray.length > 4) {
        setCityResultVisible(true);
      } else if (filteredCitiesArray.length >= 1) {
        setCityResultVisible(true);
      } else {
        setCityResultVisible(false);
      }
    } else {
      setCityResultVisible(false);
      setErrorMsgVisible(false);
    }
  };
  const GKEY = "AIzaSyClhEC4DhecYRAyD3qKg7YXTmikgBI5Wx4";
  const getLocationFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GKEY}`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const latitude = data.results[0].geometry.location.lat;
        const longitude = data.results[0].geometry.location.lng;
        return [latitude, longitude];
      } else {
        throw new Error(
          "Geocode was not successful for the following reason: " + data.status
        );
      }
    } catch (error) {
      throw new Error(
        "Error in getLocationFromAddress calling: https://maps.googleapis.com/maps/api/geocode/json" +
          error.message
      );
    }
  };
  const handleContinueBtnClick = async (e) => {
    e.preventDefault();
    const correctCity = albanianCities.some((c) => c.city === cityOrZip.trim());
    if (cityOrZip.trim() === "" || !correctCity) {
      setErrorMsgVisible(true);
    } else {
      let city = cityOrZip + ", Albania";
      let latLong = await getLocationFromAddress(city);
      setErrorMsgVisible(false);
      console.log(billPrice, cityOrZip);
      const data = {
        flag: 1,
        billp: billPrice,
        city: cityOrZip,
        hlat: latLong[0],
        hlng: latLong[1],
      };
      setCityOrZip("");

      navigate(`/form`, { state: data });
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const newOpacity = scrollPosition > 20 ? 1 : 0;
      setNavOpacity(newOpacity);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <section className="showcaseSection position-relative">
        <div className="video-background">
          <video autoPlay muted loop id="myVideo">
            <source src={vdo} type="video/mp4" />
          </video>
        </div>
        <div className="video-overlay"></div>
        <section className="position-relative">{/* <Navbar /> */}</section>

        {/* Showcase CONTENT */}
        <div className="container-xl px-xl-0 px-5 vdmarginHomePage">
          <div className="row align-items-start align-items-lg-center gy-2">
            <div className="col-md-8 showcase-content">
              <div className="col-content pe-0 pe-md-5">
                <h1 className="display-4 text-light text-center text-md-start fw-bold mt-5 headingContent">
                  INVESTONI NË PANELE FOTOVOLTAIKE DHE FILLONI TË KURSENI
                </h1>
                <h1 className="text-primary-col my-4 text-center text-md-start">
                  Me Best Solar Albania
                </h1>
                <p className="lead my-4 text-center text-white text-md-start">
                  {" "}
                  Ju mund të kurseni me miliona lekë duke instaluar panele
                  fotovoltaike. Plotësoni të dhënat dhe një nga ekspertët tanë
                  do t’ju sjellë shumë shpejt një ofertë.{" "}
                </p>
              </div>
              <div className="row my-5 align-items-center justify-content-center">
                <div className="col-xl-4 col-md-10">
                  <div className="card mb-3 showcase-card">
                    <div className="row row-lg g-0 justify-content-center">
                      <div className="col-3 pt-4 ps-2 text-center">
                        <img
                          src={loyalityimg}
                          className="img-fluid showcase-card-icon"
                          alt=""
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="card-body">
                          <h5 className="card-title text-muted text-center text-md-start">
                            Konsulencë falas
                          </h5>
                          <p className="card-text text-secondary text-center text-md-start">
                            Flisni me një ekspert për të marrë zgjidhjen më të përshtatshme për nevojat tuaja
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-10">
                  <div className="card mb-3 showcase-card">
                    <div className="row row-lg g-0 justify-content-center">
                      <div className="col-3 pt-4 ps-2 text-center">
                        <img
                          src={adjustimg}
                          className="img-fluid showcase-card-icon"
                          alt=""
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="card-body">
                          <h5 className="card-title text-muted text-center text-md-start">
                            Instalim profesional
                          </h5>
                          <p className="card-text text-secondary text-center text-md-start">
                            Mësoni sa do t'ju kushtojnë instalimi i paneleve
                            fotovoltaike dhe merrni një ofertë.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-10">
                  <div className="card mb-3 showcase-card">
                    <div className="row row-lg g-0 justify-content-center">
                      <div className="col-3 pt-4 ps-2 text-center">
                        <img
                          src={telemimg}
                          className="img-fluid showcase-card-icon"
                          alt=""
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="card-body">
                          <h5 className="card-title text-muted text-center text-md-start">
                            Financimi
                          </h5>
                          <p className="card-text text-secondary text-center text-md-start">
                            Informohuni mbi mundësitë e ndryshme të financimit
                            dhe grantet për energjinë diellore.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 py-5" id="formBox">
              {/* Page 1 */}
              <div
                className="formBox d-flex flex-column justify-content-center formContainer"
                id="boxPage-1"
              >
                <h2 className="text-light text-center mb-3">
                  Zbuloni sa kurseni duke instaluar panelet fotovoltaike.
                </h2>
                <h5 className="text-primary-col">
                  Merrni konsulencë dhe një ofertë falas
                </h5>
                <form>
                  <div className="form-group d-flex flex-column">
                    <label
                      htmlFor="price-range"
                      className="form-label text-light text-center lead w-100 fw-normal"
                    >
                      Sa paguani mesatarisht në muaj për energjinë elektrike?
                    </label>
                    <div className="per-month text-light mb-2">
                      <span id="billPrice_pg1">{billPrice}</span> Lek/muaj
                    </div>
                    <input
                      type="range"
                      className="form-range custom-range text-primary-col"
                      min="1"
                      max="20"
                      id="price-range"
                      value={billPrice / 10000}
                      onChange={handlePriceRangeChange}
                    />
                    <div className="d-flex justify-content-between">
                      <p className="text-light">5.000 Lek</p>
                      <p className="text-light">Më shumë se 200.000 Lek</p>
                    </div>
                    <div className="form-group mb-2 position-relative">
                      <input
                        className="form-control text-center"
                        type="text"
                        id="cityOrZip_pg1"
                        placeholder="Vendos qytetin"
                        required
                        autoComplete="off"
                        value={cityOrZip}
                        onChange={handleCityOrZipChange}
                      />
                      {cityResultVisible && (
                        <div
                          className="city-result"
                          id="cityResult_pg2"
                          style={{
                            height: "180px",
                          }}
                        >
                          <ul id="resultCityZip_pg1">
                            {filteredCities.map((city, index) => (
                              <li key={index} onClick={handleCityClick}>
                                {/* <i className="fa-solid fa-location-dot text-primary-col"></i> */}
                                <IoLocationSharp className="text-primary-col locationIconSize" />
                                {city.city}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {errorMsgVisible && (
                        <small
                          id="cityOrZipErrorMsg_pg1"
                          style={{ color: "orangered" }}
                        >
                          Ju lutem vendosni një qytet ose kod postal.
                        </small>
                      )}
                    </div>
                    <button
                      className="btn btn-primary text-light fw-500 homepageFormButton"
                      type="button"
                      id="continueBtn_pg1_index"
                      onClick={handleContinueBtnClick}
                    >
                      LLOGARIT KURSIMIN
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*2. why to go solar  */}
      <section id="whySolar" className="bg-light py-5">
        <div className="container-lg">
          <div className="text-center pb-4">
            <h2>Përse të investoni në panele fotovoltaike?</h2>
          </div>
          <div className="d-flex flex-md-row justify-content-md-between flex-column solar-flex-container">
            <div className="flex-item position-relative">
              <img src={imgsakurseni1} alt="" />
              <div className="overlay">
                <div className="d-flex flex-column text-center box-content">
                  <h2 className="text-light mb-3">
                    Kurseni 90% të faturës së energjisë elektrike
                  </h2>
                  <p className="text-light lead">
                    Një biznes kursen 90% të energjisë elektrike për të gjithë
                    periudhën, duke e rikthyer investimin brenda vitit të tretë.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-item position-relative">
              <img src={imgrritvleren2} alt="" />
              <div className="overlay">
                <div className="d-flex flex-column text-center box-content">
                  <h2 className="text-light mb-3">
                    Fitoni pavarësi energjitike
                  </h2>
                  <p className="text-light lead">
                    Panelet fotovoltaike janë investim fantastik për shtëpinë
                    tuaj, pasi ia rrit vlerën në mënyrë të konsiderueshme.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-item position-relative">
              <img
                src={imgfinancimipaneleve3}
                className="positioned-image"
                alt=""
              />
              <div className="overlay">
                <div className="d-flex flex-column text-center box-content">
                  <h2 className="text-light mb-3">Përqafoni energjinë e rinovueshme</h2>
                  <p className="text-light lead">
                    Ekzistojnë skema të ndryshme financimi për biznese dhe
                    individë që duan të instalojnë panele fotovoltaike.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center py-4">
            <h3>
              Keni më shumë pyetje për investimin në panelet fotovoltaike?
            </h3>
          </div>
          <div className="text-center">
            <Link to="/form">
              <button className="btn btn-primary-transparent btn-square fw-bold Whytogosolar-Formbutton">
                MERRNI NJË OFERTË PA PAGESË
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* 3. Steps to Energy Indepence */}
      <section id="stepsToEnergySection" className="bg-navy py-5">
        <div className="container-lg steps-to-energy">
          <div className="text-center pt-5 pb-3">
            <h2 className="fw-bold steps-heading">
              3 hapa për të patur pavarësi energjitike
            </h2>
          </div>
          <div className="row fixed-width py-4 px-md-5 px-3 mx-auto steps-flex-container">
            <div className="card-container col-md-4 py-md-0 py-3 d-flex-flex-column justify-content-center">
              <div className="text-center pb-3">
                <BiMobileAlt className="text-primary-col fs-1" />
              </div>
              <div>
                <h4>
                  <span className="text-primary-col">Hapi 1: </span>Lini një
                  orar për konsulencë
                </h4>
              </div>
              <div>
                <p className="lead">
                  Stafi ynë do ju kontaktojë për të lënë një takim apo bisedë me
                  telefon, për të saktësuar kostot dhe nevojat tuaja për
                  energji.
                </p>
              </div>
            </div>
            <div className="card-container col-md-4 py-md-0 py-3 d-flex-flex-column justify-content-center">
              <div className="text-center pb-3">
                <BiFile className="text-primary-col fs-1" />
              </div>
              <div>
                <h4>
                  <span className="text-primary-col">Hapi 2: </span>Merrni një
                  plan të personalizuar
                </h4>
              </div>
              <div>
                <p className="lead">
                  Pasi të kemi gjithë informaiconin e nevojshëm, do ju
                  prezantojmë me ofertën më të mirë dhe mundësitë e financimit
                  për instalimin e paneleve fotovoltaike.
                </p>
              </div>
            </div>
            <div className="card-container col-md-4 py-md-0 py-3 d-flex-flex-column justify-content-center">
              <div className="text-center pb-3">
                <BiBrightness className="text-primary-col fs-1" />
              </div>
              <div>
                <h4>
                  <span className="text-primary-col">Hapi 3: </span>Fitoni
                  pavarësinë energjitike
                </h4>
              </div>
              <div>
                <p className="lead">
                  Pasi të merrni vendimin, është rradha jonë t’ju bëjmë të
                  pavarur në energji.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center pt-3 pb-5 steps-btn-container">
            <Link to="/form">
              <button className="btn btn-primary btn-primary-over-colored-background btn-square fw-bold">
                MERRNI NJË OFERTË PA PAGESË
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* 4. Bolog  */}
      <section className="bg-light blogSection2">
        <div className="container">
          <div className="text-center pb-3">
            <h2 className="fw-bold steps-heading HomeBlog">
              Artikujt e fundit
            </h2>
          </div>
          <div className="row fixed-width g-4 mx-auto pb-4 pt-5">
            <div className="col-lg-4 col-md-6 card-holder">
              <div className="card blog-card">
                <div className="position-relative blog-card-img">
                  <Link to="/Blog1HowToChooseTheRightCompany">
                    <img
                      src={blog1Img}
                      className="card-img-top blog-img"
                      alt="..."
                    />
                    <div className="overlay">
                      <p className="lead fw-normal box-content">
                        Lexo më shumë
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="card-body">
                  <h4 className="card-title fw-normal blog-card-title">
                    Si të përzgjidhni kompaninë e duhur për investimin në panele
                    fotovoltaike
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-holder">
              <div className="card blog-card">
                <div className="position-relative blog-card-img">
                  <Link to="/Blog2HowMuchEnergyProduces">
                    <img
                      src={blog2Img}
                      className="card-img-top blog-img"
                      alt="..."
                    />
                    <div className="overlay">
                      <p className="lead fw-normal box-content">
                        Lexo më shumë
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="card-body">
                  <h4 className="card-title fw-normal blog-card-title">
                    Sa energji prodhojnë panelet fotovoltaike?
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pt-5 pb-3 ">
            <Link
              to="blog"
              className="homeBlogButton btn btn-primary-transparent btn-square fw-bold"
            >
              Lexo blogjet tona
            </Link>
          </div>
        </div>
      </section>
      {/* 5. Form  */}
      <section id="contactUsSection" className="bg-navy py-5">
        <div className="container-lg steps-to-energy">
          <div className="text-center py-3">
            <h2 className="text-primary-col">
              <span className="caveat-text">
                Jeni një biznes që konsumon shumë energji?
              </span>
            </h2>
            <h3 className="text-light fw-bold steps-heading pt-1 pb-4">
              Na kontaktoni per të zbuluar sa mund të kurseni!
            </h3>
            <p className="lead text-light fw-normal">
              <span className="text-primary-col fw-500">
                <a href="./pages/formBox.html" className="text-primary-col">
                  Plotësoni formularin
                </a>
              </span>{" "}
              ose dërgoni pyetjen duke plotësuar formin më poshtë. Në të kundërt
              mund të dërgoni një email në{" "}
              <a
                href="mailto:info@bestsolar.al"
                style={{ color: "white" }}
                className="contacthov"
                onMouseEnter={(e) =>
                  (e.target.style.color = "var(--primary-color)")
                }
                onMouseLeave={(e) => (e.target.style.color = "white")}
              >
                info@bestsolar.al
              </a>
            </p>
          </div>
          <form className="home-form mx-auto px-4">
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                id="name"
                placeholder="Emri juaj"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                id="phone"
                placeholder="Telefon"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control form-control-lg"
                type="number"
                id="question"
                placeholder="Pyetja juaj"
                rows="3"
                cols="50"
              ></textarea>
            </div>
            <button className="btn btn-primary btn-square fw-bold fs-5 w-100">
              Dërgo
            </button>
            <div className="contact-us-privacy">
              <p className="mx-auto">
                <FontAwesomeIcon icon={faLock} className="pe-1" />
                Ne e vlerësojmë privatësinë tuaj. Informacioni juaj është 100% I
                sigurtë. Ne nuk e shpërndajmë apo shpërdorojmë atë.{" "}
              </p>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Home;
