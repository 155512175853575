import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import blog1img from "../../assets/img/blog-1/blog1_main.png";
import blog2mainhowmuchenergy from "../../assets/img/blog-2/blog2_main_how-much-energy.png";
import "../../assets/css/style.css";
function Blog(props) {
  const {navOpacity,setNavOpacity}=props;
  useEffect(() => {
  setNavOpacity(1)
  }, []);
  return (
    <>
      <section className="bg-light blogSection1">
        <div className="container py-5 text-center blog-title-container">
          <div className="row">
            <div className="col col-xl-8 mx-auto">
              <h1 className="display-3 mb-3 fw-normal">Blogjet Tona</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light blogSection2">
        <div className="container">
          <div className="row fixed-width g-4 mx-auto pb-4 pt-5">
            <div className="col-lg-4 col-md-6 card-holder">
              <div className="card blog-card">
                <div className="position-relative blog-card-img">
                  <Link to="/Blog1HowToChooseTheRightCompany">
                    <img
                      src={blog1img}
                      className="card-img-top blog-img"
                      alt="..."
                    />
                    <div className="overlay">
                      <p className="lead fw-normal box-content">
                        Lexo më shumë
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="card-body">
                  <h4 className="card-title fw-normal blog-card-title">
                    Si të përzgjidhni kompaninë e duhur për investimin në panele
                    fotovoltaike
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-holder">
              <div className="card blog-card">
                <div className="position-relative blog-card-img">
                  <Link to="/Blog2HowMuchEnergyProduces">
                    <img
                      src={blog2mainhowmuchenergy}
                      className="card-img-top blog-img"
                      alt="..."
                    />
                    <div className="overlay">
                      <p className="lead fw-normal box-content">
                        Lexo më shumë
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="card-body">
                  <h4 className="card-title fw-normal blog-card-title">
                    Sa energji prodhojnë panelet fotovoltaike?
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
