import React from "react";
import { Link } from "react-router-dom";
function FormPage7() {
  return (
    <>
      <div
        className="formBox d-flex flex-column justify-content-center formContainer p-0 "
        id="boxPage-7"
      >
        <div className="d-flex flex-column infoBox2">
          <div className="py-4">
            <h2 className="text-primary-col text-center mb-3">
              Kërkesa u dërgua me sukses!
            </h2>
            <p className="text-light lead fw-500 mb-0">
              Një nga specialistët tanë do t’ju kontaktojë së shpejti për t’ju
              dhënë informacionin e saktë mbi kursimin dhe investimin e kërkuar,
              bazuar në të dhënat tuaja.
            </p>
            <p>
              <Link to="/" className="lead text-primary-col">
                Shko tek faqja kryesore
              </Link>
            </p>
            <p className="text-light text-center fw-semibold mt-3 mb-0">
              Nëse fatura juaj e energjisë është nën 10000 lekë/në muaj,
              investimi në panele nuk ju sjell kursim.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPage7;
